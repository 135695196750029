/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import './HomePage.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SiteHeader from '../../components/header/SiteHeader';
import SiteFooter from '../../components/footer/SiteFooter';

function Main() {
  const { t } = useTranslation();

  const mainPageServicesSectionCards = [{
    title: t('landing_service_tow_truck'),
    url: 'img/sos/tow.webp',
    urlTo: '/tow',
  }, {
    title: t('landing_service_tire_change'),
    url: 'img/sos/tyre.jpg',
    urlTo: '/tyre',
  }, {
    title: t('landing_service_battery'),
    url: 'img/sos/battery.jpeg',
    urlTo: '/battery',
  }, {
    title: t('landing_service_fuel'),
    url: 'img/sos/fuel.jpg',
    urlTo: '/fuel',
  }, {
    title: t('landing_service_driver'),
    url: 'img/sos/driver.jpg',
    urlTo: '/sober',
  }];

  return (
    <div className="body" style={{ backgroundColor: '#212529' }}>
      <SiteHeader />

      <div role="main" className="main">
        <div className="container py-5 my-5">
          <div className="row justify-content-center">
            <div className="col-lg-9 col-xl-8 text-center">
              <div className="overflow-hidden">
                <a id="servicesTab">
                  <h2 className="font-weight-bold text-light line-height-1 mb-1 appear-animation animated maskUp appear-animation-visible" data-appear-animation="maskUp" data-appear-animation-delay={250}>
                    {t('landing_services_title')}
                  </h2>
                </a>
              </div>
              <div className="d-inline-block custom-divider divider divider-primary divider-small my-3">
                <hr className="my-0 appear-animation animated customLineProgressAnim appear-animation-visible" data-appear-animation="customLineProgressAnim" data-appear-animation-delay={600} />
              </div>
              <p className="font-weight-light text-3-5 mb-5 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay={500}>
                {t('landing_services_subtitle')}
              </p>
            </div>
          </div>
          <div className="row row-gutter-sm mb-5 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay={750}>
            {
							mainPageServicesSectionCards.map(({ title, url, urlTo }, index) => (
  <div className={`col-sm-6  text-center mb-4 mt-4 mb-lg-0 ${index === 3 ? 'col-lg-6' : 'col-lg-4'}`}>
    <div className="row">
      <div className={index === 3 ? 'col-lg-4' : 'col-lg-12'} />
      <div className={index === 3 ? 'col-lg-8' : 'col-lg-12'}>
        <Link to={urlTo} className="text-decoration-none">
          <div className="mb-1 custom-thumb-info-style-1 thumb-info thumb-info-no-borders thumb-info-no-borders-rounded thumb-info-lighten">
            <div className="thumb-info-wrapper">
              <img src={url} className="img-fluid" alt="" style={{ aspectRatio: 4 / 3, objectFit: 'cover' }} />
            </div>
            <h3 className="text-transform-none font-weight-bold text-5 mt-2 mb-0" style={{ lineHeight: '1.5' }}>{title}</h3>
          </div>
        </Link>
      </div>
    </div>
  </div>
							))
						}
          </div>
        </div>
      </div>
      <SiteFooter />
    </div>
  );
}

export default Main;
