/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import { useTranslation } from 'react-i18next';
import './HomePage.css';
import SiteHeader from '../../components/header/SiteHeader';
import SiteFooter from '../../components/footer/SiteFooter';
import LoginModal from '../loginModal/LoginModal';
// import services from './services';
import { useShowLoginModal } from '../../lib/atoms';

function Main() {
  const { t } = useTranslation();
  const [showLoginModal] = useShowLoginModal();

  // const { service } = props;

  return (
    <div
      className="body"
      style={{
        backgroundImage: 'url(img/sos/part.jpeg)',
        backgroundSize: '100vw',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#212529',
        backgroundPositionY: '120px',

      }}
    >
      <SiteHeader />

      {showLoginModal && <LoginModal />}

      <div className="" style={{ textAlign: 'center', marginTop: '60px', padding: '10px' }}>
        <h2
          style={{
            color: 'white',
            // textShadow: '-1px -1px 0 #053a5c,1px -1px 0 #053a5c,-1px 1px 0 #053a5c,1px 1px 0 #053a5c',
            fontSize: 'max(2.5vw, 22px)',
            whiteSpace: 'nowrap',
          }}
          className="font-weight-bold"
        >
          {t('partners_page_title')}
        </h2>
        <div className="row">
          <div className="col-lg-3 col-1" />
          <div className="col-lg-6 col-10">
            <p className="font-weight-bold text-light" style={{ fontSize: '16px', marginTop: '60px' }}>
              {t('partners_page_intro')}
            </p>
          </div>
          <div className="col-lg-3 col-1" />
        </div>
        <div className="row" style={{ marginTop: '70px' }}>
          <div className="col-lg-1" />
          <div className="col-lg-10">
            <div className="row">
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-2">
                    <img
                      style={{
                        height: '40px',
                        width: '40px',
                        marginLeft: '15px',
                        marginTop: '-7px',
                      }}
                      src="img/sos/checked.png"
                      alt=""
                    />
                  </div>
                  <div className="col-10" style={{ textAlign: 'left' }}>
                    <h5 style={{ color: '#fec50c', fontSize: '20px', lineHeight: '1.5' }}>
                      {t('partners_page_section1_title')}
                    </h5>
                    <p className="font-weight-bold text-light" style={{ fontSize: '16px' }}>
                      {t('partners_page_section1_para1')}
                    </p>
                    <p className="font-weight-bold text-light" style={{ fontSize: '16px' }}>
                      {t('partners_page_section1_para2')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-2">
                    <img
                      style={{
                        height: '40px',
                        width: '40px',
                        marginLeft: '15px',
                        marginTop: '-7px',
                      }}
                      src="img/sos/checked.png"
                      alt=""
                    />
                  </div>
                  <div className="col-10" style={{ textAlign: 'left' }}>
                    <h5 style={{ color: '#fec50c', fontSize: '20px', lineHeight: '1.5' }}>
                      {t('partners_page_section2_title')}
                    </h5>
                    <p className="font-weight-bold text-light" style={{ fontSize: '16px' }}>
                      {t('partners_page_section2_para')}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <img
                      style={{
                        height: '40px',
                        width: '40px',
                        marginLeft: '15px',
                        marginTop: '-7px',
                      }}
                      src="img/sos/checked.png"
                      alt=""
                    />
                  </div>
                  <div className="col-10" style={{ textAlign: 'left' }}>
                    <h5 style={{ color: '#fec50c', fontSize: '20px', lineHeight: '1.5' }}>
                      {t('partners_page_section3_title')}
                    </h5>
                    <p className="font-weight-bold text-light" style={{ fontSize: '16px' }}>
                      {t('partners_page_section3_para1')}
                      <br />
                      {t('partners_page_section3_para2')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-2">
                    <img
                      style={{
                        height: '40px',
                        width: '40px',
                        marginLeft: '15px',
                        marginTop: '-7px',
                      }}
                      src="img/sos/checked.png"
                      alt=""
                    />
                  </div>
                  <div className="col-10" style={{ textAlign: 'left' }}>
                    <h5 style={{ color: '#fec50c', fontSize: '20px', lineHeight: '1.5' }}>
                      {t('partners_page_section4_title')}
                    </h5>
                    <p className="font-weight-bold text-light" style={{ fontSize: '16px' }}>
                      {t('partners_page_section4_para1')}
                    </p>
                    <p className="font-weight-bold text-light" style={{ fontSize: '16px' }}>
                      {t('partners_page_section4_para2')}
                    </p>
                    <p className="font-weight-bold text-light" style={{ fontSize: '16px' }}>
                      {t('partners_page_section4_para3')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-1" />
        </div>
      </div>

      <SiteFooter />
    </div>
  );
}

export default Main;
