/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  TextField,
  Grid,
} from '@mui/material';

function TranslationRow({ slug, initialTranslations, onSave }) {
  const { t } = useTranslation();
  const [ka, setKa] = useState(initialTranslations.ka || '');
  const [en, setEn] = useState(initialTranslations.en || '');
  const [ru, setRu] = useState(initialTranslations.ru || '');
  const [az, setAz] = useState(initialTranslations.az || '');
  const [hy, setHy] = useState(initialTranslations.hy || '');

  const handleSave = () => {
    const updatedTranslations = {
      ka,
      en,
      ru,
      az,
      hy,
    };
    onSave(slug, updatedTranslations);
  };

  // Custom styles for input fields and labels
  const inputStyles = {
    backgroundColor: '#333333',
    color: '#ffffff',
  };

  const labelStyles = {
    color: '#ffffff',
    opacity: 0.5,
  };

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label="Slug"
          fullWidth
          value={slug}
          disabled
          InputLabelProps={{ style: labelStyles }}
          InputProps={{
            style: inputStyles,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label="Georgian (ka)"
          fullWidth
          value={ka}
          onChange={(e) => setKa(e.target.value)}
          InputLabelProps={{ style: labelStyles }}
          InputProps={{
            style: inputStyles,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label="English (en)"
          fullWidth
          value={en}
          onChange={(e) => setEn(e.target.value)}
          InputLabelProps={{ style: labelStyles }}
          InputProps={{
            style: inputStyles,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label="Russian (ru)"
          fullWidth
          value={ru}
          onChange={(e) => setRu(e.target.value)}
          InputLabelProps={{ style: labelStyles }}
          InputProps={{
            style: inputStyles,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label="Azerbaijani (az)"
          fullWidth
          value={az}
          onChange={(e) => setAz(e.target.value)}
          InputLabelProps={{ style: labelStyles }}
          InputProps={{
            style: inputStyles,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label="Armenian (hy)"
          fullWidth
          value={hy}
          onChange={(e) => setHy(e.target.value)}
          InputLabelProps={{ style: labelStyles }}
          InputProps={{
            style: inputStyles,
          }}
        />
      </Grid>
      {/* Move the Save button to the end for xs size */}
      <Grid item xs={12} sm={6} md={4}>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          fullWidth
          sx={{ mt: { xs: 2, sm: 0 }, backgroundColor: '#ffffff', color: '#333333' }}
        >
          {t('translation_save_button')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default TranslationRow;
