/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card, CardContent, CardMedia, Typography, Grid, Box, Tooltip, CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar, Backdrop,
} from '@mui/material';
import { format } from 'date-fns';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import DeleteIcon from '@mui/icons-material/Delete'; // Import Delete icon

import C from '../../lib/consts';
import SiteHeader from '../../components/header/SiteHeader';
import SiteFooter from '../../components/footer/SiteFooter';

import { usersTypes } from '../../lib/enums';
import { useUser } from '../../lib/atoms';
import apiCall from '../../api';

function BlogPage() {
  const { t } = useTranslation();
  const backendUrl = C.BACKEND_URL;

  const { user, userType } = useUser();

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state for fetching blogs
  const [visibleCount, setVisibleCount] = useState(6); // State to track the number of visible blogs
  const [openDialog, setOpenDialog] = useState(false); // Dialog state
  const [selectedBlog, setSelectedBlog] = useState(null); // State to track the selected blog for deletion
  const [deleting, setDeleting] = useState(false); // Loading state for delete action
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state

  useEffect(() => {
    // Function to fetch blogs from the backend
    const fetchBlogs = async () => {
      try {
        const response = await fetch(`${backendUrl}/get-blogs`, {
          method: 'GET',
        });

        // Check if the response is successful
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setBlogs(data.blogs);
      } catch (error) {
        throw new Error(`Error fetching blogs: ${error}`);
      } finally {
        setLoading(false); // Set loading to false once the data is fetched
      }
    };

    fetchBlogs();
  }, [backendUrl]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return format(date, 'dd MMMM yyyy');
  };

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 6); // Increase the number of visible blogs by 6
  };

  const handleDeleteClick = (blog) => {
    setSelectedBlog(blog); // Set the selected blog for deletion
    setOpenDialog(true); // Open the dialog
  };

  const handleDeleteConfirm = async () => {
    if (!selectedBlog) return;

    setDeleting(true); // Set deleting state to true to show loading indicator

    try {
      // Call the API to delete the blog
      const deleteBlogData = { blogId: selectedBlog.id, idToken: user.accessToken };
      await apiCall(`${backendUrl}/delete-blog`, deleteBlogData);

      // Remove the deleted blog from the state
      setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog.id !== selectedBlog.id));

      setOpenDialog(false);
      setSelectedBlog(null);
      setSnackbarOpen(true);
    } catch (error) {
      throw new Error(`Error deleting blog: ${error}`);
    } finally {
      setDeleting(false); // Set deleting state to false after the API call is finished
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false); // Close the dialog
    setSelectedBlog(null); // Clear the selected blog
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false); // Close the snackbar
  };

  return (
    <>
      <SiteHeader />
      <Box className="container" sx={{ padding: 4 }}>
        <Typography variant="h4" gutterBottom textAlign="center" sx={{ mb: 3, color: 'white' }}>
          {t('blog_page_title')}
        </Typography>
        {loading ? (
          // Show loader when loading is true
          <Box sx={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh',
          }}
          >
            <CircularProgress />
          </Box>
        ) : (
          // Show blog cards when loading is false
          <>
            <Grid container spacing={3}>
              {blogs
                .filter((blog) => blog.data.title && blog.images.length > 0)
                .slice(0, visibleCount) // Slice the array to show only the number of visible blogs
                .map((blog) => (
                  <Grid item xs={12} sm={6} md={4} key={blog.id}>
                    <Link to={`/blog/${blog.id}`} style={{ textDecoration: 'none' }}>
                      <Card
                        sx={{
                          maxWidth: 345,
                          borderRadius: '10px',
                          cursor: 'pointer',
                          backgroundColor: 'transparent', // Make card background transparent
                          boxShadow: 'none', // Remove the shadow for a cleaner look
                          position: 'relative', // Relative position for delete button placement
                        }}
                      >
                        <CardMedia
                          component="img"
                          alt={blog.data.title}
                          height="200"
                          image={blog.images[0]}
                          title={blog.data.title}
                          sx={{ height: 200, objectFit: 'cover', borderRadius: '10px' }} // Rounded corners for image
                        />
                        <CardContent sx={{
                          paddingLeft: 0, paddingRight: 0, paddingTop: 2, paddingBottom: 2,
                        }}
                        >
                          <Tooltip title={blog.data.title} arrow>
                            <Typography
                              variant="h6"
                              component="div"
                              sx={{
                                fontSize: 16,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                                whiteSpace: 'normal',
                                minHeight: '2.8em',
                                lineHeight: '1.4em',
                                color: 'white',
                              }}
                            >
                              {blog.data.title}
                            </Typography>
                          </Tooltip>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 3 }}>
                            <Typography variant="body2" color="white">
                              {formatDate(blog.created)}
                            </Typography>
                            {userType === usersTypes.SUPER && (
                              <IconButton
                                color="error"
                                size="small"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleDeleteClick(blog);
                                }}
                                aria-label="delete"
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Box>
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                ))}
            </Grid>
            {visibleCount < blogs.filter((blog) => blog.data.title && blog.images.length > 0).length && (
              // Show the "Load More" button only if there are more blogs to load
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Button variant="contained" onClick={handleLoadMore}>
                  Load More
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
      <SiteFooter />

      {/* Dialog for delete confirmation */}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        PaperProps={{
          sx: {
            backgroundColor: '#333333',
            color: 'white',
          },
        }}
      >
        <DialogTitle sx={{ color: 'white' }}>Are you sure you want to delete this blog?</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: 'white' }}>
            Blog will be deleted permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} sx={{ color: 'white' }} disabled={deleting}>
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
            disabled={deleting}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Full-Screen Loading Indicator */}
      <Backdrop open={deleting} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Snackbar for Success Notification */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Blog successfully deleted"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />

    </>
  );
}

export default BlogPage;
