/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

import { useTranslation } from 'react-i18next';

import './HomePage.css';

import SiteHeader from '../../components/header/SiteHeader';
import SiteFooter from '../../components/footer/SiteFooter';
import LoginModal from '../loginModal/LoginModal';

import { useShowLoginModal } from '../../lib/atoms';

function Main() {
  const { t } = useTranslation();
  const [showLoginModal] = useShowLoginModal();

  return (
    <div className="body" style={{ backgroundColor: '#212529' }}>
      <SiteHeader />

      {showLoginModal && <LoginModal />}

      <div role="main" className="main">
        <div className="container my-5 pt-md-4 pt-xl-0">
          <div className="row align-items-center justify-content-center pb-4 mb-5">
            <div className="col-lg-6 pb-sm-4 pb-lg-0 mb-5 mb-lg-0">
              <div style={{ textAlign: 'center' }} className="position-relative">
                <div data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 0.2, 'transition': true, 'transitionDuration': 1000, 'isInsideSVG': true}" style={{ width: 'fit-content', display: 'inline-block', textAlign: 'left' }}>
                  <h1 className="font-weight-bold text-10" style={{ color: '#fec50e' }}>{t('about_page_title')}</h1>
                  <p className="font-weight-bold text-5 text-light">
                    <span style={{ color: '#fec50e' }}>SOS AUTO</span>
                    {' '}
                    {t('about_page_sos_auto_intro')}
                  </p>
                  <p className="text-5 text-light">
                    <span style={{ color: '#fec50e' }}>SOS AUTO</span>
                    {t('about_page_sos_auto_description')}
                  </p>
                  <p className=" text-5 text-light">
                    {t('about_page_sos_auto_success_first')}
                    {' '}
                    <span style={{ color: '#fec50e' }}>SOS AUTO</span>
                    {t('about_page_sos_auto_success_second')}

                  </p>
                </div>
              </div>
            </div>
            <div style={{ textAlign: 'center' }} className="col-10 col-md-9 col-lg-6 ps-lg-5 pe-5 appear-animation animated fadeInRightShorterPlus appear-animation-visible" data-appear-animation="fadeInRightShorterPlus" data-appear-animation-delay={1450} data-plugin-options="{'accY': -200}">
              <div className="position-relative">
                <div data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 0.2, 'transition': true, 'transitionDuration': 1000, 'isInsideSVG': true}">
                  <img src="img/sos/about.jpg" style={{ maxHeight: '495px', width: '100%', objectFit: 'contain' }} alt="" />
                </div>
              </div>
            </div>
            <div className="col-12  align-items-center" style={{ marginTop: '30px' }}>
              <div style={{ textAlign: 'center' }} className="position-relative">

                <p className="font-weight-bold text-7 text-light ">
                  {t('about_page_download_call')}
                  {' '}
                  <span style={{ color: '#fec50e' }}>SOS AUTO</span>
                  {t('about_page_drive_safe')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SiteFooter />
    </div>
  );
}

export default Main;
