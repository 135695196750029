/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */

import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill, { Quill } from 'react-quill';
import 'quill/dist/quill.snow.css';
import QuillResizeImage from 'quill-resize-image';
import {
  Button, Dialog, DialogContent, DialogActions, IconButton,
  TextField, Snackbar, Alert, Backdrop, CircularProgress,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SendIcon from '@mui/icons-material/Send';

import apiCall from '../../api';
import { useUser } from '../../lib/atoms';
import { usersTypes } from '../../lib/enums';

// Register the resizeImage module
Quill.register('modules/resize', QuillResizeImage);

// Register FiraGO font with Quill
const Font = Quill.import('formats/font');
Font.whitelist = ['FiraGO', 'serif', 'monospace'];
Quill.register(Font, true);

// Styles
const styles = {
  container: {
    width: '100%',
  },
  titleField: {
    marginBottom: '20px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
      color: 'white',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'white',
    },
  },
  quillEditor: {
    height: '600px',
    fontFamily: 'FiraGO',
    backgroundColor: '#f9f9f9',
  },
  actionsContainer: {
    marginTop: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  uploadButton: {
    display: 'flex',
    alignItems: 'center',
  },
  hiddenFileInput: {
    display: 'none',
  },
  imagePreview: {
    width: '100%',
  },
};

function CreateBlog() {
  const { t } = useTranslation();
  const { user, userType } = useUser();
  const [uploadedImage, setUploadedImage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [title, setTitle] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [isSubmitting, setIsSubmitting] = useState(false); // State for loading
  const quillRef = useRef(null);

  const editorModules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'video'],
      ['clean'],
      [{ align: [] }, { color: [] }, { background: [] }],
    ],
    resize: {
      locale: {},
    },
  };

  const editorFormats = [
    'font', 'header', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'link', 'image', 'video',
    'align', 'color', 'background',
  ];

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSubmit = async () => {
    const quillContent = quillRef.current.getEditor().root.innerHTML;

    // Validation checks
    if (!title) {
      showSnackbar(t('create_blog_title_validation'), 'error');
      return;
    }
    if (!quillContent || quillContent === '<p><br></p>') {
      showSnackbar(t('create_blog_content_validation'), 'error');
      return;
    }
    if (!uploadedImage) {
      showSnackbar(t('create_blog_image_validation'), 'error');
      return;
    }

    setIsSubmitting(true); // Set loading state to true

    const createBlogEndpoint = '/create-blog';
    const addBlogPictureEndpoint = '/blog-add-picture';

    const createBlogContent = {
      data: {
        title,
        content: quillContent,
      },
      idToken: user.accessToken,
    };

    if (userType === usersTypes.SUPER) {
      try {
        const blogResponse = await apiCall(createBlogEndpoint, createBlogContent);
        if (blogResponse.status !== 'OK') {
          showSnackbar(t('create_blog_creation_failed'), 'error');
          return;
        }

        const blogId = blogResponse.blog.id;
        console.log(blogResponse);

        if (uploadedImage) {
          const formData = new FormData();
          formData.append('pic', uploadedImage);

          const data = {
            pic: uploadedImage,
            formData,
            blogId,
            idToken: user.accessToken,
          };

          console.log(data);

          const imageResponse = await apiCall(addBlogPictureEndpoint, data, 'multipart/form-data');
          if (imageResponse.status !== 'OK') {
            throw new Error('Failed to upload image');
          }

          console.log('Image upload result:', imageResponse);
        }

        showSnackbar(t('create_blog_success_creation'), 'success');
      } catch (error) {
        showSnackbar(t('create_blog_unexpected_error'), 'error');
        console.error('Error in blog creation or image upload:', error);
      } finally {
        setIsSubmitting(false); // Set loading state to false
      }
    }
  };

  return (
    <div className="quill-container" style={styles.container}>
      <TextField
        label={t('create_blog_title_label')}
        variant="outlined"
        fullWidth
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        sx={styles.titleField}
        InputLabelProps={{ style: { color: 'white' } }}
        InputProps={{ style: { color: 'white' } }}
      />

      <ReactQuill
        ref={quillRef}
        theme="snow"
        modules={editorModules}
        formats={editorFormats}
        style={styles.quillEditor}
      />

      <div style={styles.actionsContainer}>
        <div style={styles.uploadButton}>
          <input
            accept="image/*"
            style={styles.hiddenFileInput}
            id="raised-button-file"
            type="file"
            onChange={handleImageUpload}
          />
          <label htmlFor="raised-button-file">
            <Button variant="contained" component="span" startIcon={<CloudUploadIcon />}>
              {t('create_blog_upload_button')}
            </Button>
          </label>
          {uploadedImage && (
            <IconButton color="primary" onClick={handleOpenDialog} style={{ marginLeft: '10px' }}>
              <VisibilityIcon />
            </IconButton>
          )}
        </div>
        <Button
          variant="contained"
          color="primary"
          endIcon={<SendIcon />}
          onClick={handleSubmit}
        >
          {t('create_blog_submit_button')}
        </Button>
      </div>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogContent>
          {uploadedImage && <img src={URL.createObjectURL(uploadedImage)} alt="Uploaded" style={styles.imagePreview} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {t('create_blog_image_close_button')}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Backdrop with CircularProgress for full-screen loading */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <style>
        {`
        .ql-editor {
          color: white !important;
        }
        .ql-editor.ql-blank::before {
          color: rgba(255, 255, 255, 0.6) !important;
        }
        .ql-editor ul, .ql-editor ol {
          padding-left: 1.5em;
          margin-left: 1em;
        }
        .ql-editor li {
          display: list-item;
          margin-bottom: 0.5em;
        }
        .ql-editor ul > li::before {
          content: '•';
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
        .ql-editor ol {
          counter-reset: list-counter;
        }
        .ql-editor ol > li {
          counter-increment: list-counter;
        }
        .ql-editor ol > li::before {
          content: counter(list-counter) '. ';
          display: inline-block;
          width: 1em;
          margin-left: -1.5em;
          margin-right: 0.5em;
          text-align: right;
        }
        `}
      </style>
    </div>
  );
}

export default CreateBlog;
