/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
/* eslint-disable no-prototype-builtins */
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert, Snackbar, Divider, Box, Button, Typography, CircularProgress, Backdrop,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddTranslationDialog from './TranslationDialog';
import { TranslationContext } from '../../contexts/TranslationContext';
import TranslationRow from '../../components/TranslationRow';
import apiCall from '../../api';
import { usersTypes } from '../../lib/enums';
import { useUser } from '../../lib/atoms';

function SiteTexts() {
  const { t } = useTranslation();
  const { user, userType } = useUser();
  const { translations, setTranslations } = useContext(TranslationContext);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false); // State for managing the loader
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const handleSaveTranslation = (slug, trans) => {
    // Update the texts object with the updated translations
    const updatedTexts = {
      ka: { ...translations.ka, [slug]: trans.ka },
      en: { ...translations.en, [slug]: trans.en },
      hy: { ...translations.hy, [slug]: trans.hy },
      az: { ...translations.az, [slug]: trans.az },
      ru: { ...translations.ru, [slug]: trans.ru },
    };

    if (userType === usersTypes.SUPER) {
      const newTextData = { texts: updatedTexts, idToken: user.accessToken };

      setLoading(true); // Start loader
      apiCall('/update-texts', newTextData)
        .then((response) => {
          console.log('Success:', response);
          setTranslations(updatedTexts);
          setSuccessMessage(t('translation_update_success'));
        })
        .catch((err) => {
          console.error('Error updating texts:', err);
          setError(t('translation_update_fail'));
        })
        .finally(() => {
          setLoading(false); // Stop loader
        });
    } else {
      setTranslations(updatedTexts);
      setSuccessMessage(t('translation_update_success'));
    }
  };

  const handleAddTranslation = (newTranslation) => {
    const slugExists = translations?.ka?.hasOwnProperty(newTranslation.slug);

    if (slugExists) {
      setError(`${t('translation_the_slug')} "${newTranslation.slug}" ${('translation_slug_exists')}`);
      return;
    }

    // Update the texts object with the new translation
    const updatedTexts = {
      ka: { ...translations.ka, [newTranslation.slug]: newTranslation.translations.ka },
      en: { ...translations.en, [newTranslation.slug]: newTranslation.translations.en },
      hy: { ...translations.hy, [newTranslation.slug]: newTranslation.translations.hy },
      az: { ...translations.az, [newTranslation.slug]: newTranslation.translations.az },
      ru: { ...translations.ru, [newTranslation.slug]: newTranslation.translations.ru },
    };

    if (userType === usersTypes.SUPER) {
      const newTextData = { texts: updatedTexts, idToken: user.accessToken };

      setLoading(true); // Start loader
      apiCall('/update-texts', newTextData)
        .then((response) => {
          console.log('Success:', response);
          setTranslations(updatedTexts);
          setSuccessMessage(t('translation_add_success')); // Set success message
        })
        .catch((err) => {
          console.error('Error updating texts:', err);
          setError(t('translation_add_fail'));
        })
        .finally(() => {
          setLoading(false); // Stop loader
        });
    } else {
      setTranslations(updatedTexts);
      setSuccessMessage(t('translation_add_success')); // Set success message
    }
  };

  const handleCloseError = () => {
    setError(null);
  };

  const handleCloseSuccess = () => {
    setSuccessMessage('');
  };

  // Pagination logic
  const slugs = Object.keys(translations?.ka || {});
  const totalPages = Math.ceil(slugs.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentSlugs = slugs.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Generate clickable page numbers with ellipses
  const getPageNumbers = () => {
    const pages = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) pages.push('...');
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) pages.push('...');
      pages.push(totalPages);
    }

    return pages;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div>
      {/* <AddTranslationDialog onAdd={handleAddTranslation} /> */}
      {error && (
      <Snackbar
        open
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      )}
      {successMessage && (
      <Snackbar
        open
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
      )}

      {loading && (
        <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {translations && translations.ka ? (
        <>
          {currentSlugs.map((slug, index) => (
            <React.Fragment key={slug}>
              <TranslationRow
                slug={slug}
                initialTranslations={{
                  ka: translations.ka[slug] || '',
                  en: translations.en[slug] || '',
                  ru: translations.ru[slug] || '',
                  az: translations.az[slug] || '',
                  hy: translations.hy[slug] || '',
                }}
                onSave={handleSaveTranslation}
              />
              {index < currentSlugs.length - 1 && (
                <Divider
                  sx={{
                    my: 6,
                    backgroundColor: '#1976d2',
                  }}
                />
              )}
            </React.Fragment>
          ))}

          {/* Pagination Controls */}
          <Box sx={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3,
          }}
          >
            <Button
              sx={{ mx: 1 }}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <ArrowBackIcon />
            </Button>

            {/* Clickable Page Numbers */}
            {pageNumbers.map((page, index) => (
              typeof page === 'number' ? (
                <Button
                  key={index}
                  sx={{
                    mx: 0.5,
                    minHeight: '36px',
                    fontWeight: page === currentPage ? 700 : 400,
                    color: 'white',
                    backgroundColor: page === currentPage ? 'primary.main' : 'transparent',
                    '&:hover': {
                      backgroundColor: page === currentPage ? 'primary.dark' : 'primary.light',
                    },
                  }}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </Button>
              ) : (
                <Typography
                  key={index}
                  sx={{
                    mx: 0.5,
                    fontSize: '1.2rem',
                    lineHeight: '36px',
                    fontWeight: 400,
                    color: 'white',
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  {page}
                </Typography>
              )
            ))}

            <Button
              sx={{ mx: 1 }}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <ArrowForwardIcon />
            </Button>
          </Box>
        </>
      ) : (
        <p>Loading translations...</p>
      )}
    </div>
  );
}

export default SiteTexts;
