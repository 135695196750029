/* eslint-disable no-console */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import React, { createContext, useState, useEffect } from 'react';
import i18n from '../i18n/i18n';
import C from '../lib/consts';

const TranslationContext = createContext();

function TranslationProvider({ children }) {
  const backendUrl = C.BACKEND_URL;

  // Retrieve the saved language from localStorage, or default to i18n.language
  const savedLanguage = localStorage.getItem('selectedLanguage') || i18n.language;
  const [language, setLanguage] = useState(savedLanguage);
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async (lng) => {
      try {
        const response = await fetch(`${backendUrl}/get-texts`);
        const data = await response.json();
        setTranslations(data.texts);

        if (data.texts[lng]) {
          i18n.addResources(lng, 'translation', data.texts[lng]);
          i18n.changeLanguage(lng);
        } else {
          console.error(`No translations found for language: ${lng}`);
        }
      } catch (error) {
        console.error('Error fetching translations:', error);
      }
    };

    fetchTranslations(language);
  }, [language]);

  const changeLanguage = (lng) => {
    setLanguage(lng);
    localStorage.setItem('selectedLanguage', lng);
  };

  return (
    <TranslationContext.Provider
      value={{
        language, changeLanguage, translations, setTranslations,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
}

export { TranslationContext, TranslationProvider };
