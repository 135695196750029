/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import dayjs from 'dayjs';

function TowTruckRequestDialog({
  open,
  formData,
  errors,
  handleChange,
  handleClose,
  handleSubmit,
  carType,
  transportationType,
  t,
  loading,
}) {
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose(); // Allow closing when reason is not 'backdropClick'
        }
      }}
      PaperProps={{
        sx: {
          backgroundColor: '#333333', // Dialog background color
          color: 'white', // Text color
        },
      }}
    >
      <DialogTitle sx={{ color: 'white' }}>{t('tow_truck_request_title')}</DialogTitle>
      <DialogContent>
        {/* Tow Truck Type Select */}
        <FormControl fullWidth margin="normal" error={!!errors.towTruckType}>
          <InputLabel sx={{ color: 'white' }}>{t('tow_truck_type')}</InputLabel>
          <Select
            name="towTruckType"
            value={formData.towTruckType}
            label={t('tow_truck_type')}
            onChange={handleChange}
            sx={{ color: 'white', backgroundColor: '#333333' }}
          >
            {Object.keys(carType).map((key) => (
              <MenuItem key={key} value={key}>
                {carType[key]}
              </MenuItem>
            ))}
          </Select>
          {errors.towTruckType && (
            <FormHelperText sx={{ color: 'red' }}>{errors.towTruckType}</FormHelperText>
          )}
        </FormControl>

        {/* Car Count Field */}
        <TextField
          label={t('tow_order_car_count')}
          name="carCount"
          type="number"
          fullWidth
          margin="normal"
          value={formData.carCount}
          onChange={handleChange}
          error={!!errors.carCount}
          helperText={errors.carCount}
          sx={{ input: { color: 'white' }, borderColor: errors.carCount ? 'red' : '' }}
          InputLabelProps={{ style: { color: 'white' } }}
        />

        {/* Car Model Field */}
        <TextField
          label={t('tow_create_order_car_model')}
          name="carModel"
          fullWidth
          margin="normal"
          value={formData.carModel}
          onChange={handleChange}
          error={!!errors.carModel}
          helperText={errors.carModel}
          sx={{ input: { color: 'white' } }}
          InputLabelProps={{ style: { color: 'white' } }}
        />

        {/* Transportation Type Select */}
        <FormControl fullWidth margin="normal" error={!!errors.transportType}>
          <InputLabel sx={{ color: 'white' }}>{t('tow_order_transport_type')}</InputLabel>
          <Select
            name="transportType"
            label={t('tow_order_transport_type')}
            value={formData.transportType}
            onChange={handleChange}
            sx={{ color: 'white', backgroundColor: '#333333' }}
          >
            {Object.keys(transportationType).map((key) => (
              <MenuItem key={key} value={key}>
                {transportationType[key]}
              </MenuItem>
            ))}
          </Select>
          {errors.transportType && (
            <FormHelperText sx={{ color: 'red' }}>{errors.transportType}</FormHelperText>
          )}
        </FormControl>

        {/* From City Field */}
        <TextField
          label={t('tow_order_from_city')}
          name="fromCity"
          fullWidth
          margin="normal"
          value={formData.fromCity}
          onChange={handleChange}
          error={!!errors.fromCity}
          helperText={errors.fromCity}
          sx={{ input: { color: 'white' } }}
          InputLabelProps={{ style: { color: 'white' } }}
        />

        {/* To City Field */}
        <TextField
          label={t('tow_order_to_city')}
          name="toCity"
          fullWidth
          margin="normal"
          value={formData.toCity}
          onChange={handleChange}
          error={!!errors.toCity}
          helperText={errors.toCity}
          sx={{ input: { color: 'white' } }}
          InputLabelProps={{ style: { color: 'white' } }}
        />

        {/* Date Field */}
        <TextField
          label={t('tow_create_order_date')}
          name="date"
          type="date"
          fullWidth
          margin="normal"
          value={formData.date}
          onChange={handleChange}
          error={!!errors.date}
          helperText={errors.date}
          InputLabelProps={{
            shrink: true,
            style: { color: 'white' },
          }}
          inputProps={{
            min: dayjs().format('YYYY-MM-DD'),
          }}
          sx={{
            input: { color: 'white' },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('tow_order_close')}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : t('tow_order_submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TowTruckRequestDialog;
