/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import './App.css';
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import HomePage from './pages/homePage/HomePage';
import ServicesPage from './pages/homePage/ServicesPage';
import TyrePage from './pages/homePage/TyrePage';
import PartnersPage from './pages/homePage/PartnersPage';
import ClientsPage from './pages/homePage/ClientsPage';
import ContactPage from './pages/homePage/ContactPage';
import AboutUs from './pages/homePage/AboutUs';
import ProfilePage from './pages/profilePage/ProfilePage';
import CreateOrderPage from './pages/orderPage/CreateOrderPage';
import OrderPage from './pages/orderPage/OrderPage';
import FaqPage from './pages/faqPage/faqPage';
import BlogPage from './pages/blogPage/Blogs';
import BlogPost from './pages/blogPage/BlogPost';

function Scroller() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <div />;
}

function App() {
  return (
    <Router>
      <div className="App">
        <Scroller />
        <Routes>
          <Route path="/tow" element={<TyrePage service="tow" />} />
          <Route path="/tyre" element={<TyrePage service="tyre" />} />
          <Route path="/battery" element={<TyrePage service="battery" />} />
          <Route path="/sober" element={<TyrePage service="sober" />} />
          <Route path="/fuel" element={<TyrePage service="fuel" />} />
          <Route path="/partners" element={<PartnersPage />} />
          <Route path="/clients" element={<ClientsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/blogs" element={<BlogPage />} />
          <Route path="/blog/:id" element={<BlogPost />} />
          <Route path="/profile" element={<ProfilePage section="/profile" />} />
          <Route path="/my-services" element={<ProfilePage section="/my-services" />} />
          <Route path="/tow-truck-company" element={<ProfilePage section="/tow-truck-company" />} />
          <Route path="/services-map" element={<ProfilePage section="/services-map" />} />
          <Route path="/order-list" element={<ProfilePage section="/order-list" />} />
          <Route path="/payment-methods" element={<ProfilePage section="/payment-methods" />} />
          <Route path="/verification-requests" element={<ProfilePage section="/verification-requests" />} />
          <Route path="/verified-services" element={<ProfilePage section="/verified-services" />} />
          <Route path="/user-list" element={<ProfilePage section="/user-list" />} />
          <Route path="/create-blog" element={<ProfilePage section="/create-blog" />} />
          <Route path="/site-texts" element={<ProfilePage section="/site-texts" />} />
          <Route path="/service-review/:id" element={<ProfilePage section="/serviceReview" />} />
          <Route path="/order" element={<CreateOrderPage />} />
          <Route path="/order/:id" element={<OrderPage />} />
          <Route path="/faq" element={<FaqPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
