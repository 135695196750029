/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import {
  Button, Container, Grid, TextField, Select, MenuItem, Typography, Box,
  InputLabel, FormControl, Snackbar, Alert, CircularProgress,
} from '@mui/material';
import SiteHeader from '../../components/header/SiteHeader';
import SiteFooter from '../../components/footer/SiteFooter';

import apiCall from '../../api';
import classNames from '../../lib/classNames';
import enums from '../../lib/enums';
import { useUser } from '../../lib/atoms';
import consts from '../../lib/consts';
import maps from '../../lib/maps';

const twoLocationProblems = [
  enums.problemType.SOBER,
  enums.problemType.TOW,
  enums.problemType.TOW_SPIDER,
];

function CreateOrderPage() {
  const { t } = useTranslation();
  const { user, userType } = useUser();
  const navigate = useNavigate();

  const [problemType, setProblemType] = useState(enums.problemType.TOW_SPIDER);
  const [comment, setComment] = useState('');
  const [availableServices, setAvailableServices] = useState([]); // For storing available services
  const [selectedService, setSelectedService] = useState(''); // For super user service selection
  const [center, setCenter] = useState({
    lat: 41.7131688,
    lng: 44.7826625,
  });

  const [marker, setMarker] = useState({
    lat: 41.7131688,
    lng: 44.7826625,
  });

  const [markerTo, setMarkerTo] = useState({
    lat: 41.7131688,
    lng: 44.7826625 + 0.0005,
  });

  const [errorMessage, setErrorMessage] = useState(''); // Error message state for snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State to manage Snackbar visibility
  const [loading, setLoading] = useState(false); // Loading state for submit button

  const problemTypes = {
    tow_jumbo: t('problem_type_tow_3t'),
    tow_spider: t('problem_type_tow_spider'),
    tow: t('problem_type_evacuator'),
    battery: t('problem_type_accumulator'),
    tyre: t('problem_type_tire'),
    locked_door: t('problem_type_close_door'),
    fuel: t('problem_type_fuel'),
    sober: t('problem_type_driver'),
  };

  // Fetch available services based on problemType
  const fetchAvailableServices = async () => {
    if (user?.uid && userType) {
      const serviceWorkersEndpoint = '/get-available-services';
      const payLoad = { idToken: user.accessToken, problemType };

      try {
        const response = await apiCall(serviceWorkersEndpoint, payLoad);
        setAvailableServices(response.services || []); // Save the available services in state
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    }
  };

  // Call the fetch function on initial render and whenever problemType changes
  useEffect(() => {
    fetchAvailableServices();
  }, [user?.uid, userType, problemType]);

  // Function to close the Snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Create order function for super user
  const createOrderAdminRequest = async () => {
    if (!problemType || (userType === enums.usersTypes.SUPER && !selectedService)) {
      setErrorMessage(
        !problemType ? 'Problem type is required' : 'Select service is required',
      );
      setSnackbarOpen(true);
      return; // Stop execution if validation fails
    }

    setLoading(true); // Set loading state to true

    try {
      const data = {
        locFrom: marker,
        ...(twoLocationProblems.includes(problemType) ? { locTo: markerTo } : {}),
        problemType,
        comment,
        uid: user.uid,
        userType,
        idToken: user.accessToken,
        serviced: selectedService, // Super user selected service
      };
      const order = await apiCall('/create-order-admin', data); // Super user API call
      if (order?.status === enums.statuses.OK) {
        navigate(`/order/${order.orderId}`);
      }
    } catch (error) {
      console.error('Error getting service!', error);
    } finally {
      setLoading(false); // Reset loading state after response
    }
  };

  // Regular create order function
  const createOrderRequest = async () => {
    if (!problemType) {
      setErrorMessage('Problem type is required');
      setSnackbarOpen(true);
      return; // Stop execution if validation fails
    }

    setLoading(true); // Set loading state to true

    try {
      const data = {
        locFrom: marker,
        ...(twoLocationProblems.includes(problemType) ? { locTo: markerTo } : {}),
        problemType,
        comment,
        uid: user.uid,
        userType,
        idToken: user.accessToken,
      };
      const order = await apiCall('/create-order', data);
      if (order?.status === enums.statuses.OK) {
        navigate(`/order/${order.orderId}`);
      }
    } catch (error) {
      console.error('Error getting service!', error);
    } finally {
      setLoading(false); // Reset loading state after response
    }
  };

  const containerStyle = {
    width: '100%',
    height: '400px',
  };

  const mapClicked = (event) => {
    console.log(event.latLng.lat(), event.latLng.lng());
  };

  const markerClicked = (marker) => {
    console.log(marker);
  };

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      setMarker({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      setMarkerTo({
        lat: position.coords.latitude,
        lng: position.coords.longitude + 0.0005,
      });
    });
  };

  const iconC = {
    url: '/img/sos/car.png',
    scaledSize: { width: 40, height: 40 },
  };

  const iconH = {
    url: '/img/sos/home.png',
    scaledSize: { width: 40, height: 40 },
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: consts.MAPS_API,
  });

  return (
    <div>
      <SiteHeader />

      <Container>
        <Box mt={3} mb={3}>
          <Typography variant="h4" align="center">
            {t('create_order_title')}
          </Typography>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={17}
                onClick={mapClicked}
                options={{ styles: maps.streetStyle }}
              >
                <MarkerF
                  position={marker}
                  icon={iconC}
                  draggable
                  onDragEnd={(event) => setMarker({
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                  })}
                  onClick={() => markerClicked('AAAA')}
                />
                {twoLocationProblems.includes(problemType) && (
                  <MarkerF
                    position={markerTo}
                    icon={iconH}
                    draggable
                    onDragEnd={(event) => setMarkerTo({
                      lat: event.latLng.lat(),
                      lng: event.latLng.lng(),
                    })}
                    onClick={() => markerClicked('BBBB')}
                  />
                )}
              </GoogleMap>
            )}
          </Grid>

          <Grid item xs={9} />

          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={getLocation}
            >
              {t('navigate')}
            </Button>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel sx={{ color: 'white' }}>{t('problem_type')}</InputLabel>
              <Select
                value={problemType || ''}
                onChange={(e) => setProblemType(e.target.value)}
                variant="outlined"
                label={t('problem_type')}
                sx={{
                  // Custom style for the selected text
                  color: 'white',
                  '.MuiOutlinedInput-input': {
                    color: 'white', // Selected text is white
                  },
                  '.MuiSelect-icon': {
                    color: 'white', // Dropdown arrow icon is white
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white', // Border color is white
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white', // Border color on hover is white
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white', // Border color when focused is white
                  },
                }}
              >
                {Object.entries(problemTypes).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Input for Super User */}
          {userType === enums.usersTypes.SUPER && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel sx={{ color: 'white' }}>{t('select_service')}</InputLabel>
                <Select
                  value={selectedService}
                  onChange={(e) => setSelectedService(e.target.value)}
                  variant="outlined"
                  label={t('select_service')}
                  sx={{
                    // Custom style for the selected text
                    color: 'white',
                    '.MuiOutlinedInput-input': {
                      color: 'white', // Selected text is white
                    },
                    '.MuiSelect-icon': {
                      color: 'white', // Dropdown arrow icon is white
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'white', // Border color is white
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'white', // Border color on hover is white
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'white', // Border color when focused is white
                    },
                  }}
                >
                  {availableServices.map((service) => (
                    <MenuItem key={service.id} value={service.id}>
                      {service.carNumber}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              label={t('comment')}
              type="text"
              value={comment || ''}
              onChange={(e) => setComment(e.target.value)}
              fullWidth
              variant="outlined"
              sx={{
                input: { color: 'white' },
                label: { color: 'white' },
                '.MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                },
              }}
              disabled={userType === 'company'}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={userType === enums.usersTypes.SUPER ? createOrderAdminRequest : createOrderRequest}
              disabled={loading} // Disable button when loading
              startIcon={loading && <CircularProgress size={20} color="inherit" />} // Show CircularProgress
            >
              {t('save')}
            </Button>
          </Grid>
        </Grid>
      </Container>

      {/* Snackbar for validation errors */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      <SiteFooter />
    </div>
  );
}

export default CreateOrderPage;
