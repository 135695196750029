/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import './HomePage.css';
import SiteHeader from '../../components/header/SiteHeader';
import SiteFooter from '../../components/footer/SiteFooter';
import LoginModal from '../loginModal/LoginModal';
import useServices from './services'; // Import the hook
import { useShowLoginModal } from '../../lib/atoms';

function Main(props) {
  const [showLoginModal] = useShowLoginModal();
  const { service } = props;

  const services = useServices(); // Call the hook to get services

  return (
    <div className="body" style={{ backgroundColor: '#053a5c !important' }}>
      <SiteHeader />

      {showLoginModal && <LoginModal />}

      <div role="main" className="main">
        <section className="section custom-section-background position-relative border-0 overflow-hidden m-0 p-0" style={{ backgroundColor: '#053a5c !important' }}>

          <div style={{
            height: '60vw',
            maxHeight: 400,
            backgroundImage: `url(${services[service].img})`,
            backgroundSize: 'cover',
          }}
          >
            <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            >
              <h2
                style={{
                  color: services[service].color,
                  textShadow: '-1px -1px 0 #053a5c,1px -1px 0 #053a5c,-1px 1px 0 #053a5c,1px 1px 0 #053a5c',
                  fontSize: 'max(4.5vw, 30px)',
                  whiteSpace: 'nowrap',
                }}
                className="font-weight-bold"
              >
                {services[service].title}
              </h2>
            </div>
          </div>
        </section>

        <div className="" style={{ backgroundColor: '#053a5c !important' }}>
          <div className="container pt-3 pb-2">
            {services[service].text}
          </div>
        </div>
      </div>
      <SiteFooter />
    </div>
  );
}

export default Main;
