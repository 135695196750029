/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import './OrderPage.css';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Rating from '@mui/material/Rating';
import TextField from '@mui/material/TextField';
import SiteHeader from '../../components/header/SiteHeader';
import SiteFooter from '../../components/footer/SiteFooter';

import apiCall from '../../api';
import classNames from '../../lib/classNames';
import { useUser, useLastNotification } from '../../lib/atoms';
import maps from '../../lib/maps';
import enums from '../../lib/enums';
import texts from '../../lib/texts';
import consts from '../../lib/consts';
import utils from '../../lib/utils';

const isOrderOver = {
  [enums.orderStatuses.CANCELED_BY_DRIVER]: true,
  [enums.orderStatuses.CANCELED_BY_CLIENT]: true,
  [enums.orderStatuses.COMPLETED]: true,
};

function OrderPage() {
  const lang = 'ka';
  const { user } = useUser();
  const navigate = useNavigate();
  const { id } = useParams();

  const [order, setOrder] = useState(null);
  const [assignedOptions, setAssignedOptions] = useState([]);
  const [orderModal, setOrderModal] = useState(false);
  const [orderReviewModal, setOrderReviewModal] = useState(false);
  const [rating, setRating] = useState(4);
  const [comment, setComment] = useState('');

  const [loading, setLoading] = useState(false);
  const [forceUpdateOrder, setForceUpdateOrder] = useState(0);
  const [lastNotif] = useLastNotification();

  useEffect(() => {
    console.log(lastNotif);
    if (user?.uid && id) {
      setLoading(true);

      apiCall('/get-order', {
        uid: user.uid,
        orderId: id,
        idToken: user.accessToken,
      }).then((response) => {
        if (response.status === enums.statuses.OK) {
          setOrder(response.order);
          if (response.order?.assignedOptions) {
            setAssignedOptions(response.order.assignedOptions);
          }
        }
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    }
  }, [user?.uid, id, forceUpdateOrder, lastNotif]);

  const updateStatus = async (status) => {
    setLoading(true);
    apiCall(
      '/change-order-status',
      {
        orderId: id,
        status,
        uid: user.uid,
        idToken: user.accessToken,
      },
    ).then(() => {
      setForceUpdateOrder(forceUpdateOrder + 1);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
      setForceUpdateOrder(forceUpdateOrder + 1);
    });
  };

  const findServices = () => {
    if (user && [enums.orderStatuses.REQUESTED, enums.orderStatuses.ASSIGNED_OPTIONS].includes(order?.status)) {
      apiCall(
        '/find-service',
        {
          uid: user.uid,
          orderId: id,
          idToken: user.accessToken,
        },
      ).then((response) => {
        if (response?.services) {
          setAssignedOptions(response.services);
        }
      });
    }
  };

  const selectService = (sid) => {
    setLoading(true);
    apiCall(
      '/select-service',
      {
        uid: user.uid,
        orderId: id,
        serviceId: sid,
        redirect: window.location.href,
        idToken: user.accessToken,
      },
    ).then((response) => {
      if (response?.payment?.newCard) {
        window.location.href = response?.payment?.body?._links?.redirect?.href;
      } else {
        setForceUpdateOrder(forceUpdateOrder + 1);
      }
      setLoading(false);
    }).catch(() => setLoading(false));
  };

  const sendReview = () => {
    setLoading(true);
    apiCall(
      '/send-review',
      {
        rating,
        comment,
        orderId: id,
        idToken: user.accessToken,
      },
    ).then(() => {
      setForceUpdateOrder(forceUpdateOrder + 1);
      setLoading(false);
    }).catch(() => setLoading(false));
  };

  useEffect(() => {
    findServices();
    const intId = setInterval(findServices, 30000);
    return () => clearInterval(intId);
  }, [user, order]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: consts.MAPS_API,
  });

  const [gMap, setGMap] = useState(null);

  useEffect(() => {
    if (gMap && order) {
      const bounds = new window.google.maps.LatLngBounds();
      // Only extend bounds if locations are defined
      if (order.locFrom) bounds.extend(order.locFrom);
      if (order.locTo) bounds.extend(order.locTo);
      gMap.fitBounds(bounds);
    }
  }, [gMap, order]);

  const containerStyle = {
    width: '100%',
    height: '400px',
  };

  const iconC = {
    url: '/img/sos/car.png',
    scaledSize: { width: 40, height: 40 },
  };

  const iconH = {
    url: '/img/sos/home.png',
    scaledSize: { width: 40, height: 40 },
  };

  return (
    <div>
      <SiteHeader />

      <div role="main" className={classNames.MAIN}>
        <section className="page-header page-header-modern bg-color-dark-scale-1 page-header-md ">
          <div className="container">
            <div className="row">
              <div className="col-md-12 align-self-center p-static order-2 text-center">
                <h1 className="font-weight-bold text-light">
                  Order #
                  {utils.readableId(id)}
                </h1>
              </div>
            </div>
          </div>
        </section>
        <div className="row p-5">
          <div className={`${assignedOptions?.length > 0 ? 'col-lg-6' : ''} col-md-12 mb-3`}>
            {order
              && (
                <div className="card box-shadow-3">
                  <div className="card border-radius-2">
                    <div className="card-img-top">
                      {isLoaded && (
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          zoom={17}
                          options={{ styles: maps.streetStyle }}
                          onLoad={setGMap}
                        >
                          {/* Conditionally render markers */}
                          {order.locFrom && (
                            <MarkerF
                              position={order.locFrom}
                              icon={iconC}
                            />
                          )}
                          {order.locTo && (
                            <MarkerF
                              position={order.locTo}
                              icon={iconH}
                            />
                          )}
                        </GoogleMap>
                      )}
                    </div>
                    <div className="card-body" style={{ backgroundColor: '#444' }}>
                      <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <table style={{ color: 'white' }}>
                            <tbody>
                              <tr>
                                <td>ID:</td>
                                <td>{utils.readableId(order.id)}</td>
                              </tr>
                              <tr>
                                <td>Comment:</td>
                                <td>{order.comment}</td>
                              </tr>
                              <tr>
                                <td>Status:</td>
                                <td>{order.status}</td>
                              </tr>
                              <tr>
                                <td>Type:</td>
                                <td>{texts.problemTypes[order.problemType][lang]}</td>
                              </tr>
                              {!!order.review && (
                                <tr>
                                  <td>Review:</td>
                                  <td><Rating name="read-only" value={order.review.rating} readOnly /></td>
                                </tr>
                              )}
                              {!!order.review && (
                                <tr>
                                  <td>Review comment:</td>
                                  <td>
                                    {order.review.comment}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                      </div>

                      {((user.uid === order.clientId || user.uid === order.driverId) && !isOrderOver[order.status])
                      && (
                      <a
                        href="#"
                        className={`btn btn-rounded btn-danger mt-3 ms-3 float-end  ${loading ? 'disabled' : ''}`}
                        onClick={(event) => {
                          event.preventDefault();
                          setOrderModal({
                            question: 'Cancel Order?',
                            onYes: () => {
                              updateStatus(user.uid === order.driverId
                                ? enums.orderStatuses.CANCELED_BY_DRIVER
                                : enums.orderStatuses.CANCELED_BY_CLIENT);
                              setOrderModal(false);
                              navigate('/order-list');
                            },
                          });
                        }}
                      >
                        Cancel Order
                      </a>
                      )}

                      {(user.uid === order.driverId && order.status === enums.orderStatuses.SERVICE_SELECTED_PAYED)
                      && (
                      <a
                        href="#"
                        className={`btn btn-rounded btn-success mt-3 ms-3 float-end ${loading ? 'disabled' : ''}`}
                        onClick={(event) => {
                          event.preventDefault();
                          setOrderModal({
                            question: 'Approve Order?',
                            onYes: () => {
                              updateStatus(enums.orderStatuses.DRIVER_APPROVED);
                              setOrderModal(false);
                            },
                          });
                        }}
                      >
                        Approve order
                      </a>
                      )}

                      {(user.uid === order.driverId && order.status === enums.orderStatuses.DRIVER_APPROVED)
                      && (
                      <a
                        href="#"
                        className={`btn btn-rounded btn-success mt-3 ms-3 float-end ${loading ? 'disabled' : ''}`}
                        onClick={(event) => {
                          event.preventDefault();
                          setOrderModal({
                            question: 'Finalize order?',
                            onYes: () => {
                              updateStatus(enums.orderStatuses.COMPLETED);
                              setOrderModal(false);
                            },
                          });
                        }}
                      >
                        Finalize order
                      </a>
                      )}

                      {(user.uid === order.clientId && isOrderOver[order.status] && !order.review)
                      && (
                      <a
                        href="#"
                        className={`btn btn-rounded btn-warning mt-3 ms-3 float-end ${(loading || !!order.review) ? 'disabled' : ''}`}
                        onClick={(event) => {
                          event.preventDefault();
                          setOrderReviewModal(true);
                        }}
                      >
                        Review Order
                      </a>
                      )}

                    </div>
                  </div>
                </div>
              )}
          </div>
          <div className={`${assignedOptions?.length > 0 ? 'col-lg-6' : ''} col-md-12 `}>
            <div className="row ">

              {assignedOptions.map((s) => (
                <div className="col-lg-6 mb-3" style={{ color: 'white' }}>
                  <div className="card box-shadow-3 border-radius-2">
                    {/* <img
                      className="card-img-top"
                      src="/img/sos/evacuate-gd3c540dd6_1920.jpg"
                      alt="Card Image"
                      style={{
                        height: '100px',
                        width: '100%',
                        objectFit: 'cover',
                      }}
                    /> */}
                    <div className="card-body" style={{ backgroundColor: '#444' }}>
                      <h4 className="card-title mb-1 text-4 font-weight-bold">{texts.carType[s.carType][lang]}</h4>
                      <p className="card-text mb-2 pb-1"style={{ color: 'white' }}>
                        {`price: ${s.price} ₾`}
                      </p>
                      <p className="card-text mb-2 pb-1"style={{ color: 'white' }}>
                        {`rating: ${s.rating} ★`}
                      </p>
                      <p className="card-text mb-2 pb-1"style={{ color: 'white' }}>
                        {`time: ${Math.floor(s.time / 60)} min`}
                      </p>
                      <a
                        href="#"
                        className={`float-end btn btn-primary btn-rounded btn-with-arrow-solid mb-2 ${loading ? 'disabled' : ''}`}
                        onClick={() => selectService(s.id)}
                      >
                        Select
                        <span><i className="fas fa-chevron-right" /></span>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Modal show={!!orderModal} onHide={() => setOrderModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>{orderModal.question}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setOrderModal(false)}>
            No
          </Button>
          <Button
            variant="success"
            onClick={orderModal.onYes}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={!!orderReviewModal} onHide={() => setOrderReviewModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Review Order</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h4>How would you rate the service?</h4>
          <div>
            <Rating
              value={rating}
              onChange={(event, newValue) => setRating(newValue)}
              precision={0.5}
              size="large"
            />
          </div>
          <TextField
            label="Comment"
            fullWidth
            multiline
            value={comment}
            onChange={(event) => setComment(event.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              sendReview();
              setOrderReviewModal(false);
            }}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>

      <SiteFooter />
    </div>
  );
}

export default OrderPage;
