/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { useTranslation } from 'react-i18next';

function TyreServiceText() {
  const { t } = useTranslation();

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <h4 style={{ color: '#fec50c', margin: '30px' }}>{t('tyre_sos_app_description')}</h4>
      </div>
      <p className="font-weight-bold text-light">
        {t('tyre_service_description')}
      </p>
      <p className="font-weight-bold text-light">
        {t('tyre_service_prompt')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('tyre_how_it_works')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('tyre_service_help')}
      </p>
      <p className="font-weight-bold text-light">
        {t('tyre_real_time_tracking')}
        <br />
        {t('tyre_time_to_arrive')}
      </p>
      <p className="font-weight-bold text-light">
        {t('tyre_professional_assistants')}
        <br />
        {t('tyre_trusted_assistants')}
        <br />
        {t('tyre_trust_and_hope')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('tyre_transparent_pricing')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('tyre_pricing_and_payment')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('tyre_availability')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('tyre_services_24_7')}
      </p>
      <p className="font-weight-bold text-light">
        {t('tyre_customer_satisfaction')}
        <br />
        {t('tyre_priority')}
      </p>
      <p className="font-weight-bold text-light">
        {t('tyre_download_app')}
      </p>
    </div>
  );
}

function BatteryServiceText() {
  const { t } = useTranslation();

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <h4 style={{ color: '#fec50c', margin: '30px' }}>{t('battery_sos_app_description')}</h4>
      </div>
      <p className="font-weight-bold text-light">
        {t('battery_service_description')}
      </p>
      <p className="font-weight-bold text-light">
        {t('battery_service_prompt')}
      </p>
      <p className="font-weight-bold text-light">
        {t('battery_service_ask_us')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('battery_how_it_works')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('battery_service_help')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('battery_real_time_tracking')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('battery_time_to_arrive')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('battery_professional_assistants_first')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('battery_professional_assistants')}
        <br />
        {t('battery_trusted_assistants')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('battery_transparent_pricing')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('battery_pricing_and_payment')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('battery_availability')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('battery_services_24_7')}
      </p>
      <p className="font-weight-bold text-light">
        {t('battery_customer_satisfaction')}
        <br />
        {t('battery_priority')}
      </p>
      <p className="font-weight-bold text-light">
        {t('battery_download_app')}
      </p>
    </div>
  );
}

function TowServiceText() {
  const { t } = useTranslation();

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <h4 style={{ color: '#fec50c', margin: '30px' }}>{t('tow_sos_app_description')}</h4>
      </div>
      <p className="font-weight-bold text-light">
        {t('tow_service_description')}
      </p>
      <p className="font-weight-bold text-light">
        {t('tow_service_prompt')}
      </p>
      <p className="font-weight-bold text-light">
        {t('tow_service_ask_us')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('tow_how_it_works')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('tow_service_help')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('tow_real_time_tracking')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('tow_time_to_arrive')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('tow_professional_assistants_first')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('tow_professional_assistants')}
        <br />
        {t('tow_trusted_assistants')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('tow_transparent_pricing')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('tow_pricing_and_payment')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('tow_availability')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('tow_services_24_7')}
      </p>
      <p className="font-weight-bold text-light">
        {t('tow_customer_satisfaction')}
        <br />
        {t('tow_priority')}
      </p>
      <p className="font-weight-bold text-light">
        {t('tow_download_app')}
      </p>
    </div>
  );
}

function FuelServiceText() {
  const { t } = useTranslation();

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <h4 style={{ color: '#fec50c', margin: '30px' }}>{t('fuel_sos_app_description')}</h4>
      </div>
      <p className="font-weight-bold text-light">
        {t('fuel_service_description')}
      </p>
      <p className="font-weight-bold text-light">
        {t('fuel_service_prompt')}
      </p>
      <p className="font-weight-bold text-light">
        {t('fuel_service_ask_us')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('fuel_how_it_works')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('fuel_service_help')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('fuel_real_time_tracking')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('fuel_time_to_arrive')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('fuel_professional_assistants_first')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('fuel_professional_assistants')}
        <br />
        {t('fuel_trusted_assistants')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('fuel_transparent_pricing')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('fuel_pricing_and_payment')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('fuel_availability')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('fuel_services_24_7')}
      </p>
      <p className="font-weight-bold text-light">
        {t('fuel_customer_satisfaction')}
        <br />
        {t('fuel_priority')}
      </p>
      <p className="font-weight-bold text-light">
        {t('fuel_download_app')}
      </p>
    </div>
  );
}

function SoberDriverServiceText() {
  const { t } = useTranslation();

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <h4 style={{ color: '#fec50c', margin: '30px' }}>{t('sober_sos_app_description')}</h4>
      </div>
      <p className="font-weight-bold text-light">
        {t('sober_service_description')}
      </p>
      <p className="font-weight-bold text-light">
        {t('sober_service_prompt')}
      </p>
      <p className="font-weight-bold text-light">
        {t('sober_dont_drive')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('sober_how_it_works')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('sober_service_help')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('sober_real_time_tracking')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('sober_time_to_arrive')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('sober_professional_assistants_first')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('sober_professional_assistants')}
        <br />
        {t('sober_trusted_assistants')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('sober_transparent_pricing')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('sober_pricing_and_payment')}
      </p>
      <h5 style={{ color: '#fec50c' }}>
        {t('sober_availability')}
      </h5>
      <p className="font-weight-bold text-light">
        {t('sober_services_24_7')}
      </p>
      <p className="font-weight-bold text-light">
        {t('sober_customer_satisfaction')}
        <br />
        {t('sober_priority')}
      </p>
      <p className="font-weight-bold text-light">
        {t('sober_download_app')}
      </p>
    </div>
  );
}

function useServices() {
  const { t } = useTranslation();

  const services = {
    tyre: {
      title: t('info_tire_title'),
      color: '#fff',
      img: 'img/sos/flat.jpg',
      text: <TyreServiceText />,
    },
    battery: {
      title: t('battery_service_title'),
      color: '#fff',
      img: 'img/sos/battery.jpeg',
      text: <BatteryServiceText />,
    },
    tow: {
      title: t('tow_service_title'),
      color: '#fff',
      img: 'img/sos/tow.webp',
      text: <TowServiceText />,
    },
    fuel: {
      title: t('fuel_service_title'),
      color: '#fff',
      img: 'img/sos/fuel.jpg',
      text: <FuelServiceText />,
    },
    sober: {
      title: t('sober_service_title'),
      color: '#fff',
      img: 'img/sos/driver.jpg',
      text: <SoberDriverServiceText />,
    },
  };

  return services;
}

export default useServices;
