/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { usersTypes } from '../lib/enums';
import { useUser } from '../lib/atoms';
import apiCall from '../api';

function TowTruckViewDialog({
  open, handleClose, orderData, t,
}) {
  const { user, userType } = useUser();
  const [loading, setLoading] = useState(false); // State for circular loading
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' }); // Snackbar state

  // Mockup function for API call
  const handleAction = async (action) => {
    setLoading(true); // Start loading
    try {
      if (user.uid && userType === usersTypes.SUPER) {
        const updateOrderStatus = '/update-preorder-status';
        const payLoad = {
          idToken: user.accessToken,
          preOrderId: orderData.id,
          status: action,
        };
        const updateResponse = await apiCall(updateOrderStatus, payLoad);

        // Simulate success response
        if (updateResponse.success) {
          setSnackbar({ open: true, message: t('action_successful'), severity: 'success' });
        } else {
          throw new Error('Action failed');
        }
      }
    } catch (error) {
      setSnackbar({ open: true, message: t('action_failed'), severity: 'error' });
    } finally {
      setLoading(false); // End loading
      handleClose(); // Close the dialog
    }
  };

  // Handle Snackbar close
  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: 'success' });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: '#333333',
            color: 'white',
          },
        }}
      >
        <DialogTitle sx={{ color: 'white', display: 'flex', justifyContent: 'space-between' }}>
          {t('tow_order_details_title')}
          <IconButton onClick={handleClose} sx={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* Display order details */}
          <TextField
            label={t('tow_order_date')}
            fullWidth
            margin="normal"
            value={new Date(orderData.date).toLocaleString()}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ style: { color: 'white' } }}
            sx={{ input: { color: 'white' } }}
          />
          <TextField
            label={t('tow_order_type')}
            fullWidth
            margin="normal"
            value={orderData.type}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ style: { color: 'white' } }}
            sx={{ input: { color: 'white' } }}
          />
          <TextField
            label={t('tow_order_car_amount')}
            fullWidth
            margin="normal"
            value={orderData.carAmount}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ style: { color: 'white' } }}
            sx={{ input: { color: 'white' } }}
          />
          <TextField
            label={t('tow_order_car_model')}
            fullWidth
            margin="normal"
            value={orderData.carModel}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ style: { color: 'white' } }}
            sx={{ input: { color: 'white' } }}
          />
          <TextField
            label={t('tow_order_from')}
            fullWidth
            margin="normal"
            value={orderData.from}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ style: { color: 'white' } }}
            sx={{ input: { color: 'white' } }}
          />
          <TextField
            label={t('tow_order_to')}
            fullWidth
            margin="normal"
            value={orderData.to}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ style: { color: 'white' } }}
            sx={{ input: { color: 'white' } }}
          />
          <TextField
            label={t('tow_order_status')}
            fullWidth
            margin="normal"
            value={orderData.status}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ style: { color: 'white' } }}
            sx={{ input: { color: 'white' } }}
          />
          <TextField
            label={t('tow_order_transportation_type')}
            fullWidth
            margin="normal"
            value={orderData.transportationType}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ style: { color: 'white' } }}
            sx={{ input: { color: 'white' } }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleAction('accepted')}
            disabled={loading} // Disable button when loading
            sx={{
              backgroundColor: 'green',
              color: 'white',
              '&:hover': { backgroundColor: 'darkgreen' },
            }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : t('tow_order_accept')}
          </Button>
          <Button
            onClick={() => handleAction('rejected')}
            disabled={loading} // Disable button when loading
            sx={{
              backgroundColor: 'red',
              color: 'white',
              '&:hover': { backgroundColor: 'darkred' },
            }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : t('tow_order_reject')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Backdrop for Circular Loading */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default TowTruckViewDialog;
