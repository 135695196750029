/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Box } from '@mui/material'; // Import MUI components

// Import styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function PartnerLogoSlider({ logos }) {
  const { t } = useTranslation();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    centerMode: true, // Enable centering
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          centerMode: true, // Enable centering on smaller screens
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          centerMode: true, // Enable centering on smaller screens
        },
      },
    ],
  };

  return (
    <Box sx={{ textAlign: 'center', margin: '0 auto', maxWidth: '100%' }}>
      {/* Typography component with styles matching the example */}
      <h2 className="font-weight-bold text-light line-height-1 mb-1 appear-animation animated maskUp appear-animation-visible" data-appear-animation="maskUp" data-appear-animation-delay={250}>
        {t('landing_partners_title')}
      </h2>
      <div className="d-inline-block custom-divider divider divider-primary divider-small my-3">
        <hr className="my-0 appear-animation animated customLineProgressAnim appear-animation-visible" data-appear-animation="customLineProgressAnim" data-appear-animation-delay={600} />
      </div>

      {/* Center the slider using Grid and Box */}
      <Grid container justifyContent="center" sx={{ mt: 5 }}>
        <Grid item xs={12} md={10}>
          <Box sx={{ textAlign: 'center', mx: 'auto' }}>
            <Slider {...settings}>
              {logos.map((logo, index) => (
                <div key={index} className="slider-item">
                  <img
                    src={logo.url}
                    alt={`Partner logo ${index + 1}`}
                    style={{ width: '100px', height: '100px' }}
                  />
                </div>
              ))}
            </Slider>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

// Add prop validation
PartnerLogoSlider.propTypes = {
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default PartnerLogoSlider;
