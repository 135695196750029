/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import { useTranslation } from 'react-i18next';
import { Box, Typography, Grid } from '@mui/material';
import SiteHeader from '../../components/header/SiteHeader';
import SiteFooter from '../../components/footer/SiteFooter';
import LoginModal from '../loginModal/LoginModal';
import { useShowLoginModal } from '../../lib/atoms';

function Main() {
  const { t } = useTranslation();
  const [showLoginModal] = useShowLoginModal();

  const features = [
    {
      img: 'img/for_users_page/clock.png',
      text: t('clients_page_feature1'),
    },
    {
      img: 'img/for_users_page/map.png',
      text: t('clients_page_feature2'),
    },
    {
      img: 'img/for_users_page/map.png',
      text: t('clients_page_feature3'),
    },
    {
      img: 'img/for_users_page/gift.png',
      text: t('clients_page_feature4'),
    },
  ];

  return (
    <Box className="body">
      <SiteHeader />

      {showLoginModal && <LoginModal />}
      <div className="container">

        <Box sx={{ textAlign: 'center', marginTop: '60px', padding: '10px' }}>
          <Typography
            variant="h2"
            sx={{
              color: 'white',
              fontSize: 'max(1.5vw, 18px)',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontFamily: 'FiraGO',
              mb: 10,
            }}
          >
            <Typography
              component="span"
              sx={{
                color: '#fec50c', fontSize: 'inherit', fontWeight: 800, fontFamily: 'FiraGO',
              }}
            >
              SOS AUTO
            </Typography>
            {' '}
            {t('clients_page_title1')}
            <br />
            {t('clients_page_title2')}
          </Typography>

          <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>
            {features.map((feature, index) => (
              <Grid item xs={12} key={index} sx={{ textAlign: 'center', marginBottom: '20px' }}>
                <Box display="flex" alignItems="center" justifyContent="flex-start" sx={{ marginBottom: '10px' }}>
                  <img src={feature.img} alt="feature icon" style={{ width: '60px', height: '60px', marginRight: '20px' }} />
                  <Typography sx={{ color: 'white', fontSize: 'max(1.2vw, 18px)', fontFamily: 'FiraGO' }}>{feature.text}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>

      <SiteFooter />
    </Box>
  );
}

export default Main;
