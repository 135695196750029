/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
// ViewOrdersDialog.js
import React from 'react';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import {
  Dialog, DialogTitle, DialogContent, IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useUser } from '../lib/atoms';
import utils from '../lib/utils';
import CustomPagination from '../pages/profilePage/CustomPagination';
import { usersTypes, problemType } from '../lib/enums';

function ViewUsersOrdersDialog({
  open,
  handleClose,
  ordersData = {},
  fetchOrdersPage,
  t,
}) {
  const { userType } = useUser();
  const {
    orders = [], totalOrders = 0, currentPage = 1,
  } = ordersData;

  const problemTypes = {
    [problemType.TOW_JUMBO]: t('problem_type_tow_3t'),
    [problemType.TOW_SPIDER]: t('problem_type_tow_spider'),
    [problemType.TOW]: t('problem_type_evacuator'),
    [problemType.BATTERY]: t('problem_type_accumulator'),
    [problemType.TYRE]: t('problem_type_tire'),
    [problemType.LOCKED_DOOR]: t('problem_type_close_door'),
    [problemType.FUEL]: t('problem_type_fuel'),
    [problemType.SOBER]: t('problem_type_driver'),
  };

  const columns = [
    {
      field: 'id',
      headerName: '#',
      width: 150,
      renderCell: (params) => (
        <Link to={`/order/${params.value}`} className="nav-link text-light" target="__blank">
          {utils.readableId(params.value)}
        </Link>
      ),
    },
    {
      field: 'date',
      headerName: t('orders_date'),
      width: 200,
      valueGetter: (params) => new Date(params.row.created).toLocaleString(),
    },
    {
      field: 'type',
      headerName: t('orders_type'),
      width: 150,
      valueGetter: (params) => problemTypes[params.row.problemType],
    },
    {
      field: 'status',
      headerName: t('orders_status'),
      width: 150,
    },
    {
      field: 'price',
      headerName: t('orders_price'),
      width: 150,
      valueGetter: (params) => params.row.tx?.amount || '',
    },
  ];

  if (userType === usersTypes.SUPER) {
    columns.push(
      {
        field: 'driverPhone',
        headerName: t('orders_driver_phone'),
        width: 150,
        valueGetter: (params) => params.row.driverPhone,
      },
      {
        field: 'clientPhone',
        headerName: t('orders_client_phone'),
        width: 150,
        valueGetter: (params) => params.row.userPhone,
      },
      {
        field: 'rating',
        headerName: t('orders_rating'),
        width: 150,
        valueGetter: (params) => params.row.review?.rating || '',
      },
      {
        field: 'paymentStatus',
        headerName: t('orders_payment_status'),
        width: 150,
      },
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{ sx: { backgroundColor: '#333333', color: 'white' } }}
    >
      <DialogTitle sx={{ color: 'white', display: 'flex', justifyContent: 'space-between' }}>
        {t('user_orders')}
        <IconButton onClick={handleClose} sx={{ color: 'white' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div style={{ height: '60vh', width: '100%' }}>
          <DataGrid
            rows={orders}
            columns={columns}
            pageSize={20}
            paginationMode="server"
            rowCount={totalOrders}
            page={currentPage - 1}
            pagination
            onPageChange={(newPage) => fetchOrdersPage(ordersData.role, ordersData.roleId, newPage + 1)}
            components={{
              Pagination: () => (
                <CustomPagination
                  totalPages={Math.ceil(totalOrders / 20)}
                  currentPage={currentPage}
                  onPageChange={(page) => fetchOrdersPage(ordersData.role, ordersData.roleId, page)}
                  pageSize={20}
                  totalItems={totalOrders}
                />
              ),
            }}
            disableSelectionOnClick
            sx={{
              backgroundColor: '#212529',
              color: 'white',
              '& .MuiDataGrid-cell': { color: 'white' },
              '& .MuiDataGrid-columnHeaders': { backgroundColor: '#004168' },
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ViewUsersOrdersDialog;
