/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function CustomPagination({
  totalPages, currentPage, onPageChange, pageSize, totalItems,
}) {
  const getPageNumbers = () => {
    const pages = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) pages.push('...');
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) pages.push('...');
      pages.push(totalPages);
    }

    return pages;
  };

  const pageNumbers = getPageNumbers();

  // Calculate the range of items displayed
  const startItem = (currentPage - 1) * pageSize + 1;
  const endItem = Math.min(currentPage * pageSize, totalItems);

  return (
    <Box sx={{
      display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%',
    }}
    >
      {/* Display the range of items */}
      <Typography sx={{ ml: 2, color: 'white', fontSize: 14 }}>
        {`${startItem} - ${endItem} of ${totalItems}`}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          sx={{ mx: 0.5, minHeight: '36px', color: 'white' }}
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
        >
          <ArrowBackIcon />
        </Button>

        {/* Page Number Buttons */}
        {pageNumbers.map((page, index) => (
          typeof page === 'number' ? (
            <Button
              key={index}
              sx={{
                mx: 0.5,
                minHeight: '36px',
                fontWeight: page === currentPage ? 700 : 400,
                color: page === currentPage ? 'white' : 'inherit',
                backgroundColor: page === currentPage ? 'primary.main' : 'transparent',
                '&:hover': {
                  backgroundColor: page === currentPage ? 'primary.dark' : 'primary.light',
                },
              }}
              onClick={() => onPageChange(page)}
            >
              {page}
            </Button>
          ) : (
            <Typography
              key={index}
              sx={{
                mx: 0.5,
                fontSize: '1.2rem',
                lineHeight: '36px',
                marginBottom: '10px',
                fontWeight: 400,
                color: 'white',
                display: 'inline-flex',
                alignItems: 'center',
                minHeight: '36px',
              }}
            >
              {page}
            </Typography>
          )
        ))}

        <Button
          sx={{ mx: 0.5, minHeight: '36px', color: 'white' }}
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(currentPage + 1)}
        >
          <ArrowForwardIcon />
        </Button>
      </Box>
    </Box>
  );
}

export default CustomPagination;
