/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

import { useState, useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';

import './ProfilePage.css';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { logout } from '../../firebase';
import SiteHeader from '../../components/header/SiteHeader';
import SiteFooter from '../../components/footer/SiteFooter';
import UserInfo from './UserInfo';
import Services from './Services';
import ServicesMap from './ServicesMap';
import OrderList from './OrderList';
// import OrderList from './OrderListOld';
import PaymentMethods from './PaymentMethods';
import VerificationRequests from './VerificationRequests';
import VerifiedServices from './VerifiedServices';
import TowTruckCompany from './TowTruckCompany';
import UserList from './UserList';
// import UserList from './UserListOld';
import CreateBlog from './CreateBlog';
import SiteTexts from './SiteTexts';
import ServiceReview from './ServiceReview';
import classNames from '../../lib/classNames';
import { usersTypes } from '../../lib/enums';
import { useUser } from '../../lib/atoms';

const userTypeConfig = {
  [usersTypes.CLIENT]: {
    profile: true,
    orders: true,
    paymentMethods: true,
  },
  [usersTypes.DRIVER]: {
    profile: true,
    services: true,
    orders: true,
  },
  [usersTypes.WORKER]: {
    profile: true,
    services: true,
    orders: true,
  },
  [usersTypes.COMPANY]: {
    showEmail: true,
    profile: true,
    services: true,
    servicesMap: true,
    orders: true,
    towTruckCompany: true,
  },
  [usersTypes.SUPER]: {
    showEmail: true,
    profile: true,
    orders: true,
    verificationRequests: true,
    verifiedServices: true,
    userList: true,
    servicesMap: true,
    createBlog: true,
    towTruckCompany: true,
    siteTexts: true,
  },
};

function Profile(props) {
  const { t } = useTranslation();
  const { section: profileSection } = props;
  const { user, userType, userPhone } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams(); // Capture dynamic params like "id" from the URL

  const [activeTab, setActiveTab] = useState(profileSection || location.pathname); // Initialize with pathname

  const profilePageHeaders = {
    '/profile': t('profile_profile_title'),
    '/my-services': t('profile_services_title'),
    '/services-map': t('profile_service_map_title'),
    '/order-list': t('profile_order_list_title'),
    '/payment-methods': t('profile_payment_methods_title'),
    '/verification-requests': t('profile_verification_requests_title'),
    '/verified-services': t('profile_verified_services_title'),
    '/serviceReview': t('profile_service_verification_title'),
    '/user-list': t('profile_user_list_title'),
    '/create-blog': t('profile_create_blog_title'),
    '/site-texts': t('profile_site_texts_title'),
    '/tow-truck-company': t('profile_tow_truck_company_title'),
    logout: t('profile_logout'),
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    navigate(newValue); // Use navigate to change the route based on the selected tab
  };

  // Sync activeTab with current URL
  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  // Check if it's a dynamic route like /service-review/:id and handle rendering of dynamic components
  const isDynamicRoute = location.pathname.startsWith('/service-review');

  return (
    <div style={{ overflowX: 'auto' }}>
      <SiteHeader />

      <div role="main" className={classNames.MAIN}>
        <section className="page-header page-header-modern bg-color-dark-scale-1 page-header-md">
          <div className="container">
            <div className="row">
              <div className="col-md-12 align-self-center p-static order-2 text-center">
                <h1 className="font-weight-bold text-light text-uppercase">
                  {isDynamicRoute ? t('profile_service_verification_title') : profilePageHeaders[location.pathname]}
                </h1>
                <h5 className="text-light mt-4">
                  {user?.email || userPhone}
                </h5>
              </div>
            </div>
          </div>
        </section>

        <div className="container pb-2">
          {/* Place the Tabs at the top for horizontal alignment */}
          {!isDynamicRoute && ( // Hide Tabs for dynamic routes
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="Profile Navigation Tabs"
                indicatorColor="primary"
                textColor="inherit"
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                sx={{
                  '& .MuiTabs-scrollButtons': {
                    color: 'white', // Make the scroll buttons white
                  },
                  '& .MuiTab-root': { color: 'white' }, // Make tab text white
                }}
              >
                {userTypeConfig[userType].profile && (
                  <Tab label={t('profile_profile_title')} value="/profile" />
                )}
                {userTypeConfig[userType].services && (
                  <Tab label={t('profile_services_title')} value="/my-services" />
                )}
                {userTypeConfig[userType].servicesMap && (
                  <Tab label={t('profile_service_map_title')} value="/services-map" />
                )}
                {userTypeConfig[userType].orders && (
                  <Tab label={t('profile_order_list_title')} value="/order-list" />
                )}
                {userTypeConfig[userType].towTruckCompany && (
                  <Tab label={t('profile_tow_truck_company_title')} value="/tow-truck-company" />
                )}
                {userTypeConfig[userType].paymentMethods && (
                  <Tab label={t('profile_payment_methods_title')} value="/payment-methods" />
                )}
                {userTypeConfig[userType].verificationRequests && (
                  <Tab label={t('profile_verification_requests_title')} value="/verification-requests" />
                )}
                {userTypeConfig[userType].verifiedServices && (
                  <Tab label={t('profile_verified_services_title')} value="/verified-services" />
                )}
                {userTypeConfig[userType].userList && (
                  <Tab label={t('profile_user_list_title')} value="/user-list" />
                )}
                {userTypeConfig[userType].createBlog && (
                  <Tab label={t('profile_create_blog_title')} value="/create-blog" />
                )}
                {userTypeConfig[userType].siteTexts && (
                  <Tab label={t('profile_site_texts_title')} value="/site-texts" />
                )}
                <Tab
                  label={t('profile_logout')}
                  value="logout"
                  onClick={() => { logout(); navigate('/'); }}
                  sx={{ color: '#ED2C23 !important' }}
                />
              </Tabs>
            </Box>
          )}

          <div className="row">
            <div className="col-12">
              {activeTab === '/profile' && <UserInfo />}
              {activeTab === '/my-services' && <Services />}
              {activeTab === '/services-map' && <ServicesMap />}
              {activeTab === '/order-list' && <OrderList />}
              {activeTab === '/payment-methods' && <PaymentMethods />}
              {activeTab === '/verification-requests' && <VerificationRequests />}
              {activeTab === '/verified-services' && <VerifiedServices />}
              {isDynamicRoute && <ServiceReview id={id} />}
              {activeTab === '/user-list' && <UserList />}
              {activeTab === '/create-blog' && <CreateBlog />}
              {activeTab === '/site-texts' && <SiteTexts />}
              {activeTab === '/tow-truck-company' && <TowTruckCompany />}
            </div>
          </div>
        </div>
      </div>

      <SiteFooter />
    </div>
  );
}

export default Profile;
