/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { useTranslation } from 'react-i18next';

function SiteFooter() {
  const { t } = useTranslation();

  return (
    <footer id="footer" className="border-0 mt-10">
      <hr className="bg-light opacity-2 my-0" />
      <div className="container pb-5">
        <div className="row text-center text-md-start py-4 my-5">
          <div className="col-md-6 col-lg-3 align-self-center text-center text-md-start text-lg-center mb-5 mb-lg-0">
            <a href="/">
              <img
                alt="Porto"
                height={65}
                data-sticky-width={82}
                data-sticky-height={40}
                data-sticky-top={84}
                src="/img/sos/LOGO1.svg"
              />
            </a>
            <div className="d-flex flex-column align-items-center mt-3">
              <a href="https://play.google.com/store/apps/details?id=ge.sosauto.app&hl=en_US" target="_blank" rel="noreferrer" className="mb-3">
                <img
                  alt="Play Store"
                  src="/img/store/playstore.png"
                  style={{ maxWidth: '150px', width: '100%' }}
                />
              </a>
              <a href="https://apps.apple.com/ge/app/sos-auto/id6451263621" target="_blank" rel="noreferrer">
                <img
                  alt="App Store"
                  src="/img/store/appstore.png"
                  style={{ maxWidth: '150px', width: '100%' }}
                />
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-5 mb-5 mb-lg-0">
            <h5 className="text-transform-none font-weight-bold text-color-light text-4-5 mb-4">{t('footer_about_us')}</h5>
            <ul className="list list-unstyled">
              <li className="pb-1 mb-2">
                <span className="d-block font-weight-semibold line-height-1 text-color-grey text-3-5 mb-1">{t('footer_phone')}</span>
                <ul className="list list-unstyled font-weight-light text-3-5 mb-2">
                  <li className="text-color-light line-height-3 mb-0">
                    <a href="tel:+995577000068" className="text-decoration-none text-color-light text-color-hover-default">+995 577 000 068</a>
                  </li>
                </ul>
                <span className="d-block font-weight-semibold line-height-1 text-color-grey text-3-5 mb-1">{t('footer_email')}</span>
                <ul className="list list-unstyled font-weight-light text-3-5 mb-0">
                  <li className="text-color-light line-height-3 mb-0">
                    <a href="mailto:hello@sosauto.ge" className="text-decoration-none text-color-light text-color-hover-default">hello@sosauto.ge</a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="social-icons social-icons-medium">
              <li className="social-icons-instagram">
                <a href="https://www.instagram.com/sosauto.ge/" className="no-footer-css" target="_blank" title="Instagram" rel="noreferrer"><i className="fab fa-instagram" /></a>
              </li>
              <li className="social-icons-linkedin mx-2">
                <a href="https://www.linkedin.com/company/sos-auto-%E2%80%A2-%E1%83%A1%E1%83%9D%E1%83%A1-%E1%83%90%E1%83%95%E1%83%A2%E1%83%9D" className="no-footer-css" target="_blank" title="Linkedin" rel="noreferrer"><i className="fab fa-linkedin" /></a>
              </li>
              <li className="social-icons-facebook">
                <a href="https://www.tiktok.com/@sosauto.ge" className="no-footer-css" target="_blank" title="TikTok" rel="noreferrer"><i className="fab fa-tiktok" /></a>
              </li>
              <li className="social-icons-facebook mx-2">
                <a href="https://www.facebook.com/sosautogeo/" className="no-footer-css" target="_blank" title="Facebook" rel="noreferrer"><i className="fab fa-facebook-f" /></a>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-4 mb-5 mb-md-0">
            <h5 className="text-transform-none font-weight-bold text-color-light text-4-5 mb-4">{t('footer_services')}</h5>
            <ul className="list list-unstyled mb-0">
              <li className="mb-0">{t('footer_tow_truck_service')}</li>
              <li className="mb-0">{t('footer_tire_change')}</li>
              <li className="mb-0">{t('footer_charging_battery')}</li>
              <li className="mb-0">{t('footer_fuel_supply')}</li>
              <li className="mb-0">{t('footer_driver_service')}</li>
            </ul>
          </div>
        </div>
        <div className="container py-2">
          <div className="row">
            <div className="col">
              <p className="text-center text-3 mb-0">{t('footer_rights_reserved')}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default SiteFooter;
