import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box, CircularProgress, Typography, Container,
} from '@mui/material';

import SiteHeader from '../../components/header/SiteHeader';
import SiteFooter from '../../components/footer/SiteFooter';

import C from '../../lib/consts';

function BlogPost() {
  const backendUrl = C.BACKEND_URL;

  const { id } = useParams();
  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await fetch(`${backendUrl}/get-blog/${id}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setBlogData(data);
      } catch (error) {
        throw new Error(`Server error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogData();
  }, [id, backendUrl]);

  return (
    <>
      <SiteHeader />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '50vh',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography variant="h4" component="h1" gutterBottom sx={{ fontSize: 28, fontWeight: 600, mb: 6 }}>
              {blogData?.blog?.data?.title}
            </Typography>
            <Box sx={{ position: 'relative', minHeight: 400 }}>
              <Box
                component="img"
                src={blogData?.blog?.images[0]}
                alt="Blog post"
                sx={{
                  width: '40%',
                  height: 'auto',
                  borderRadius: '5px',
                  objectFit: 'cover',
                  float: 'left',
                  marginRight: 3,
                  marginBottom: 2,
                }}
              />
              <Box
                sx={{
                  lineHeight: 1.6,
                  fontSize: 16,
                }}
                dangerouslySetInnerHTML={{ __html: blogData?.blog?.data?.content }}
              />
            </Box>
          </>
        )}
      </Container>
      <SiteFooter />
    </>
  );
}

export default BlogPost;
