/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';
import texts from '../../lib/texts';

import SiteHeader from '../../components/header/SiteHeader';
import SiteFooter from '../../components/footer/SiteFooter';

const lang = 'ka';

function FaqPage() {
  const { t } = useTranslation();

  const mainPageFAQ = [{
    title: t('faq_page_question1'),
    text: t('faq_page_answer1'),
  }, {
    title: t('faq_page_question2'),
    text: `${t('faq_page_answer2_title')}
      <ul>
      <li>${t('faq_page_answer2_list1')}</li>
      <li>${t('faq_page_answer2_list2')}</li>
      <li>${t('faq_page_answer2_list3')}</li>
      <li>${t('faq_page_answer2_list4')}</li>
      <li>${t('faq_page_answer2_list5')}</li>
      </ul>`,
  }, {
    title: t('faq_page_question3'),
    text: t('faq_page_answer3'),
  }, {
    title: t('faq_page_question4'),
    text: t('faq_page_answer4'),
  }, {
    title: t('faq_page_question5'),
    text: t('faq_page_answer5'),
  }, {
    title: t('faq_page_question6'),
    text: `${t('faq_page_answer6')}, <br/> +995 577 00 00 68 <br/>info@sosoauto.ge`,
  }];

  return (
    <div className="body" style={{ backgroundColor: '#212529' }}>
      <SiteHeader />
      <div className="container py-5 my-5">
        <div className="row justify-content-center pb-3 mb-4">
          <div className="col-lg-9 col-xl-8 text-center">
            <div className="overflow-hidden">
              <h2 className="font-weight-bold text-light line-height-1 mb-1 appear-animation animated maskUp appear-animation-visible" data-appear-animation="maskUp" data-appear-animation-delay={250}>{t('faq_page_title')}</h2>
            </div>
            <div className="d-inline-block custom-divider divider divider-primary divider-small my-3">
              <hr className="my-0 appear-animation animated customLineProgressAnim appear-animation-visible" data-appear-animation="customLineProgressAnim" data-appear-animation-delay={650} />
            </div>
            <p className="font-weight-light text-3-5 mb-0 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay={500}>{t('faq_page_subtitle')}</p>
          </div>
        </div>
        <div className="row row-gutter-sm">
          <div className="col-md-12 col-lg-12 mb-5 mb-md-0">
            <svg className="custom-svg-2 overflow-visible" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 185 151">
              <g data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 0.1, 'transition': true, 'transitionDuration': 2000, 'isInsideSVG': true}">
                <path
                  fill="#F4F4F4"
                  className="appear-animation"
                  data-appear-animation="fadeInLeftShorterPlus"
                  data-appear-animation-delay={850}
                  d="M34.81,102.81L5.18,73.18c-2.13-2.13-2.13-5.59,0-7.72l29.63-29.63c2.13-2.13,5.59-2.13,7.72,0l29.63,29.63
                                      c2.13,2.13,2.13,5.59,0,7.72l-29.63,29.63C40.4,104.94,36.94,104.94,34.81,102.81z"
                />
              </g>
              <g data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 0.2, 'transition': true, 'transitionDuration': 2500, 'isInsideSVG': true}">
                <path
                  fill="#F4F4F4"
                  className="appear-animation"
                  data-appear-animation="fadeInLeftShorterPlus"
                  data-appear-animation-delay={1000}
                  d="M92.49,35.35L80.4,23.26c-1.75-1.75-1.75-4.59,0-6.34L92.49,4.83c1.75-1.75,4.59-1.75,6.34,0l12.09,12.09
                                      c1.75,1.75,1.75,4.59,0,6.34L98.83,35.35C97.08,37.1,94.24,37.1,92.49,35.35z"
                />
              </g>
              <g data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 0.3, 'transition': true, 'transitionDuration': 3000, 'isInsideSVG': true}">
                <path
                  fill="#F4F4F4"
                  className="appear-animation"
                  data-appear-animation="fadeInLeftShorterPlus"
                  data-appear-animation-delay={1150}
                  d="M129.88,148.41l-43.21-43.21c-2.13-2.13-2.13-5.59,0-7.72l43.21-43.21c2.13-2.13,5.59-2.13,7.72,0l43.21,43.21
                                      c2.13,2.13,2.13,5.59,0,7.72l-43.21,43.21C135.46,150.54,132.01,150.54,129.88,148.41z"
                />
              </g>
            </svg>
            <div className="accordion custom-accordion-style-1 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay={750} id="accordion1">
              { mainPageFAQ.map(({ title, text }, index) => (
                <div className="card card-default" style={{ backgroundColor: '#003a5d' }}>
                  <div className="card-header" id={`collapse${index + 1}HeadingOne`}>
                    <h4 className="card-title m-0" style={{ lineHeight: '1.5' }}>
                      <a className="accordion-toggle text-light font-weight-bold collapsed" style={{ textDecoration: 'none' }} data-bs-toggle="collapse" data-bs-target={`#collapse${index + 1}One`} aria-expanded="false" aria-controls={`collapse${index + 1}One`}>
                        {`${index + 1} - `}
                        {title}
                      </a>
                    </h4>
                  </div>
                  <div id={`collapse${index + 1}One`} className="collapse" aria-labelledby={`collapse${index + 1}HeadingOne`} data-bs-parent="#accordion">
                    <div className="card-body">
                      <p className="mb-0 text-light" dangerouslySetInnerHTML={{ __html: text }} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <SiteFooter />
    </div>
  );
}

export default FaqPage;
