/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import './HomePage.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SiteHeader from '../../components/header/SiteHeader';
import SiteFooter from '../../components/footer/SiteFooter';
import LoginModal from '../loginModal/LoginModal';
import PartnerLogoSlider from './PartnersSlider';

// import texts from '../../lib/texts';

import { useShowLoginModal } from '../../lib/atoms';

const logos = [
  { url: 'img/partners/image_123650291.JPG' },
  { url: 'img/partners/image_123650291 (8).JPG' },
  { url: 'img/partners/image_123650291 (7).JPG' },
  { url: 'img/partners/image_123650291 (6).JPG' },
  { url: 'img/partners/image_123650291 (5).JPG' },
  { url: 'img/partners/image_123650291 (4).JPG' },
  { url: 'img/partners/image_123650291 (3).JPG' },
  { url: 'img/partners/image_123650291 (2).JPG' },
  { url: 'img/partners/image_123650291 (1).JPG' },
];

function Main() {
  const { t } = useTranslation();

  const [showLoginModal] = useShowLoginModal();

  const mainPageSecondSectionText = [t('landing_app_first_text'), t('landing_app_second_text'), t('landing_app_third_text')];
  const mainPageServicesSectionCards = [{
    title: t('landing_service_tow_truck'),
    url: 'img/sos/tow.webp',
    urlTo: '/tow',
  }, {
    title: t('landing_service_tire_change'),
    url: 'img/sos/tyre.jpg',
    urlTo: '/tyre',
  }, {
    title: t('landing_service_battery'),
    url: 'img/sos/battery.jpeg',
    urlTo: '/battery',
  }, {
    title: t('landing_service_fuel'),
    url: 'img/sos/fuel.jpg',
    urlTo: '/fuel',
  }, {
    title: t('landing_service_driver'),
    url: 'img/sos/driver.jpg',
    urlTo: '/sober',
  }];

  return (
    <div className="body" style={{ backgroundColor: '#212529' }}>
      <SiteHeader />

      {showLoginModal && <LoginModal />}

      <div role="main" className="main">
        <section className="d-block d-sm-none" id="smallVideoSection">
          <video autoPlay muted playsInline loop id="myVideoSm" style={{ width: '100%' }}>
            <source src="img/sos/SOSAUTO.mp4" type="video/mp4" />
          </video>
          <div id="smallVideo"> </div>
        </section>
        <section className="section custom-section-background position-relative border-0 overflow-hidden m-0 p-0" style={{ backgroundColor: '#21252a !important' }}>
          <div
            className="position-absolute top-0 left-0 right-0 bottom-0 animated fadeIn d-none d-sm-block"
            style={{ animationDelay: '600ms', textAlign: 'center', backgroundColor: '#212529' }}
          >
            <video autoPlay muted playsinline loop id="myVideo" style={{ maxWidth: '1200px' }}>
              <source src="img/sos/SOSAUTO.mp4" type="video/mp4" />
            </video>

          </div>
          <div className="container position-relative py-sm-5 my-5 ">
            <svg
              className="custom-svg-1 d-none d-sm-block"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 649 578"
            >
              <path
                fill="#212529"
                d="M-225.5,154.7l358.45,456.96c7.71,9.83,21.92,11.54,31.75,3.84l456.96-358.45c9.83-7.71,11.54-21.92,3.84-31.75 L267.05-231.66c-7.71-9.83-21.92-11.54-31.75-3.84l-456.96,358.45C-231.49,130.66-233.2,144.87-225.5,154.7z"
              />
              <path
                className="animated customLineAnim"
                fill="none"
                stroke="#fff"
                strokeWidth="1.5"
                strokeMiterlimit={10}
                d="M416-21l202.27,292.91c5.42,7.85,3.63,18.59-4.05,24.25L198,603"
                style={{ animationDelay: '300ms', animationDuration: '5s' }}
              />
            </svg>
            <div className="row mb-5 p-relative z-index-1">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="overflow-hidden mb-1">
                  <h2
                    className="font-weight-bold text-color-grey text-4-5 line-height-2 line-height-sm-7 mb-0 appear-animation animated fadeInUpShorter appear-animation-visible"
                    data-appear-animation="maskUp"
                    data-appear-animation-delay={800}
                    style={{ animationDelay: '1100ms' }}
                  >
                    {t('landing_intro_title')}
                  </h2>
                </div>
                <h1
                  className="text-light font-weight-bold text-6 pb-2 mb-4 appear-animation animated fadeInUpShorter appear-animation-visible"
                  data-appear-animation="fadeInUpShorter"
                  data-appear-animation-delay={1100}
                >
                  {t('landing_intro_text')}
                </h1>
                <a
                  href="tel:+995577000068"
                  className="text-dark btn btn-primary custom-btn-border-radius custom-btn-arrow-effect-1 font-weight-bold text-4 px-5 btn-py-3 appear-animation animated fadeInUpShorter appear-animation-visible"
                  data-appear-animation="fadeInUpShorter"
                  data-appear-animation-delay={1300}
                  style={{ backgroundColor: '#fec50c' }}
                >
                  {t('landing_intro_button')}
                  <svg
                    className="ms-2"
                    version="1.1"
                    viewBox="0 0 15.698 8.706"
                    width={17}
                    xmlSpace="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <polygon
                      stroke="#000"
                      strokeWidth="0.1"
                      fill="#000"
                      points="11.354,0 10.646,0.706 13.786,3.853 0,3.853 0,4.853 13.786,4.853 10.646,8 11.354,8.706 15.698,4.353 "
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </section>
        <div className="container my-5 pt-md-4 pt-xl-0">
          <div className="row align-items-center justify-content-center pb-4 mb-5">
            <div className="col-lg-6 pb-sm-4 pb-lg-0 mb-5 mb-lg-0">
              <div style={{ textAlign: 'center' }} className="position-relative">
                <div data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 0.2, 'transition': true, 'transitionDuration': 1000, 'isInsideSVG': true}" style={{ width: 'fit-content', display: 'inline-block' }}>
                  {
										[0, 1, 2].map((i) => (
  <div style={{ textAlign: 'left' }}>
    <div className="appear-animation animated fadeInUpShorter appear-animation-visible" style={{ textAlign: 'left', display: 'inline-grid', animationDelay: `${450 + i * 100}ms` }} data-appear-animation="fadeInUpShorter" data-appear-animation-delay={450}>
      <div style={{ display: 'flex' }}>
        <p style={{ color: '#416e91' }} className="font-weight-bold text-5 mb-4 ">
          {`STEP ${i + 1}`}
        </p>
        <img
          style={{
            height: '40px',
            width: '40px',
            marginLeft: '15px',
            marginTop: '-7px',
          }}
          src="img/sos/checked.png"
          alt=""
        />
      </div>
    </div>
    <div style={{ textAlign: 'left', display: 'flex' }}>
      <p style={{ display: 'inline-block', animationDelay: `${450 + i * 100 + 50}ms` }} dangerouslySetInnerHTML={{ __html: mainPageSecondSectionText[i] }} className=" font-weight-bold  text-light text-5 mb-4 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay={450} />
    </div>
  </div>
										))
									}
                </div>
              </div>
            </div>
            <div style={{ textAlign: 'center' }} className="col-10 col-md-9 col-lg-6 ps-lg-5 pe-5 appear-animation animated fadeInRightShorterPlus appear-animation-visible" data-appear-animation="fadeInRightShorterPlus" data-appear-animation-delay={1450} data-plugin-options="{'accY': -200}">
              <div className="position-relative">
                <div data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 0.2, 'transition': true, 'transitionDuration': 1000, 'isInsideSVG': true}">
                  <img src="img/sos/app.png" style={{ maxHeight: '495px', width: '100%', objectFit: 'contain' }} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div>
            <PartnerLogoSlider logos={logos} />
          </div>
          {/* <div className="row pb-2">
            {
							texts.mainPageThirdSectionCards.map(({ title, text, url }) => (
  <div className="col-lg-4 text-center px-lg-5 mb-5 mb-lg-0">
    <a href="" className="text-decoration-none">
      <div className="custom-icon-box-style-1 appear-animation animated fadeInRightShorterPlus appear-animation-visible" data-appear-animation="fadeInRightShorterPlus" data-appear-animation-delay={250} data-plugin-options="{'accY': -200}" style={{ stroke: '#FFF' }}>
        <div className="custom-icon-style-1 mb-4">
          <div className="animated-icon animated fadeIn svg-fill-color-primary">
            <img
              width={50}
              src={url}
              alt=""
              data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
            />
          </div>
        </div>
        <h3 className="text-transform-none font-weight-bold text-light line-height-3 text-4-5 px-3 px-xl-5 my-2">{title[lang]}</h3>
        <p>{text[lang]}</p>
      </div>
    </a>
  </div>
							))
						}
          </div> */}
        </div>
        <div className="container py-5 my-5">
          <div className="row justify-content-center">
            <div className="col-lg-9 col-xl-8 text-center">
              <div className="overflow-hidden">
                <a id="servicesTab">
                  <h2 className="font-weight-bold text-light line-height-1 mb-1 appear-animation animated maskUp appear-animation-visible" data-appear-animation="maskUp" data-appear-animation-delay={250}>
                    {t('landing_services_title')}
                  </h2>
                </a>
              </div>
              <div className="d-inline-block custom-divider divider divider-primary divider-small my-3">
                <hr className="my-0 appear-animation animated customLineProgressAnim appear-animation-visible" data-appear-animation="customLineProgressAnim" data-appear-animation-delay={600} />
              </div>
              <p className="font-weight-light text-3-5 mb-5 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay={500}>
                {t('landing_services_subtitle')}
              </p>
            </div>
          </div>
          <div className="row row-gutter-sm mb-5 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay={750}>
            {
							mainPageServicesSectionCards.map(({ title, url, urlTo }, index) => (
  <div className={`col-sm-6  text-center mb-4 mt-4 mb-lg-0 ${index === 3 ? 'col-lg-6' : 'col-lg-4'}`}>
    <div className="row">
      <div className={index === 3 ? 'col-lg-4' : 'col-lg-12'} />
      <div className={index === 3 ? 'col-lg-8' : 'col-lg-12'}>
        <Link to={urlTo} className="text-decoration-none">
          <div className="mb-1 custom-thumb-info-style-1 thumb-info thumb-info-no-borders thumb-info-no-borders-rounded thumb-info-lighten">
            <div className="thumb-info-wrapper">
              <img src={url} className="img-fluid" alt="" style={{ aspectRatio: 4 / 3, objectFit: 'cover' }} />
            </div>
            <h3 className="text-transform-none font-weight-bold text-5 mt-2 mb-0" style={{ lineHeight: '1.5' }}>{title}</h3>
          </div>
        </Link>
      </div>
    </div>

  </div>
							))
						}
          </div>
        </div>

        <section className="section bg-transparent position-relative border-0 z-index-1 m-0 p-0">
          <svg className="custom-svg-3" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 193 495">
            <path fill="#fec50c" d="M193,25.73L18.95,247.93c-13.62,17.39-10.57,42.54,6.82,56.16L193,435.09V25.73z" />
            <path fill="none" stroke="#FFF" strokeWidth="1.5" strokeMiterlimit={10} d="M196,53.54L22.68,297.08c-12.81,18-8.6,42.98,9.4,55.79L196,469.53V53.54z" />
          </svg>
        </section>
      </div>

      <SiteFooter />
    </div>
  );
}

export default Main;
