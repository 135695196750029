/* eslint-disable react/prop-types */
import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { usersTypes } from '../lib/enums';
import { useUser } from '../lib/atoms';

function OrderViewDialog({
  open, handleClose, orderData, t,
}) {
  const { user, userType } = useUser();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { backgroundColor: '#333333', color: 'white' } }}
    >
      <DialogTitle sx={{ color: 'white', display: 'flex', justifyContent: 'space-between' }}>
        {t('order_details')}
        <IconButton onClick={handleClose} sx={{ color: 'white' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          label={t('order_date')}
          fullWidth
          margin="normal"
          value={orderData.date ?? ' '}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ style: { color: 'white' } }}
          sx={{ input: { color: 'white' } }}
        />

        <TextField
          label={t('order_status')}
          fullWidth
          margin="normal"
          value={orderData.status ?? ' '}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ style: { color: 'white' } }}
          sx={{ input: { color: 'white' } }}
        />

        <TextField
          label={t('order_price')}
          fullWidth
          margin="normal"
          value={orderData.price ?? ' '}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ style: { color: 'white' } }}
          sx={{ input: { color: 'white' } }}
        />
        <TextField
          label={t('order_type')}
          fullWidth
          margin="normal"
          value={orderData.type ?? ' '}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ style: { color: 'white' } }}
          sx={{ input: { color: 'white' } }}
        />
        {user && userType === usersTypes.SUPER && (
        <>
          <TextField
            label={t('order_client_phone')}
            fullWidth
            margin="normal"
            value={orderData.clientPhone ?? ' '}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ style: { color: 'white' } }}
            sx={{ input: { color: 'white' } }}
          />
          <TextField
            label={t('order_driver_phone')}
            fullWidth
            margin="normal"
            value={orderData.driverPhone ?? ' '}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ style: { color: 'white' } }}
            sx={{ input: { color: 'white' } }}
          />
          <TextField
            label={t('order_payment_status')}
            fullWidth
            margin="normal"
            value={orderData.paymentStatus ?? ' '}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ style: { color: 'white' } }}
            sx={{ input: { color: 'white' } }}
          />
          <TextField
            label={t('order_rating')}
            fullWidth
            margin="normal"
            value={orderData.rating ?? ' '}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ style: { color: 'white' } }}
            sx={{ input: { color: 'white' } }}
          />
        </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('order_close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OrderViewDialog;
